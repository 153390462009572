<template>
        <div class="help-us-content">
            <div class="help-us-sidebar">
                <div class="help-us-box">
                    <p>شماره کارت</p>
                    <div class="card-number">
                        <img src="../../public/upload.svg" alt="">
                        <p>۶۲۱۹-۸۶۱۹-۰۱۱۰-۵۹۳۱</p>
                        <img src="../../public/saman.svg" alt="">
                    </div>
                </div>
            <div class="help-us-box">
                <p>ما را به دیگران معرفی کنید</p>
                <div class="card-number share-box">
                    <div class="social-accounts">
                        <img class="action-btn" src="../../public/instagram.svg" @click="inviteUser('instagram')" alt="">
                        <img src="../../public/bale.svg" @click="inviteUser('bale')" alt="">
                        <img src="../../public/telegram.svg" @click="inviteUser('telegram')" alt="">
                    </div>
                    <div class="site-url">
                        <p style="width: 70%;">hafcin.ir</p>
                        <img src="../../public/upload.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
            <div class="help-us-inputs">
                <div class="help-us-box help-box-with-btn">
                    <div class="input-row">    
                        <TextInput class="input" label="نام و نام خانوادگی" placeholder="علی احمدی" />
                        <TextInput class="input" label="شماره تماس" placeholder="09********"/>
                    </div>
                    <TextInput class="input" label="مبلغ" placeholder="به ریال"/>
                    <button class="submit-btn" style="width: 200px; left: 25px; bottom: -35px;">
                        ارسال
                        <span class="material-symbols-outlined">
                            login
                        </span>
                    </button>
                </div>
            </div>
        </div>
</template>


<script>
import TextInput from '@/components/TextInput.vue';
export default {
    name: 'HelpUs',
    components: {
        TextInput
    },
    methods: {
        inviteUser: function (type) {
            if (type === "telegram") {
                window.open('https://t.me/share/url?url=%D8%A8%D8%A7%20%D8%B3%D9%84%D8%A7%D9%85%20%D8%AF%D9%88%D8%B3%D8%AA%D8%A7%D9%86%20%D8%B9%D8%B2%DB%8C%D8%B2%D8%8C%0A%0A%D8%A8%D8%A7%20%D8%A7%D9%81%D8%AA%D8%AE%D8%A7%D8%B1%20%D8%A8%D9%87%20%D8%B4%D9%85%D8%A7%20%D8%AF%D8%B9%D9%88%D8%AA%20%D9%85%DB%8C%E2%80%8C%DA%A9%D9%86%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%AC%D9%85%D8%B9%20%D9%85%D8%A7%20%D8%AF%D8%B1%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%D8%A8%D9%BE%DB%8C%D9%88%D9%86%D8%AF%DB%8C%D8%AF.%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%DB%8C%DA%A9%20NGO%20%D9%81%D8%B9%D8%A7%D9%84%20%D8%A7%D8%B3%D8%AA%20%DA%A9%D9%87%20%D8%A7%D8%B2%20%D8%B7%D8%B1%DB%8C%D9%82%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%20www.hafcin.ir%20%D8%AF%D8%B1%20%D8%AD%D8%A7%D9%84%20%DA%A9%D8%A7%D8%B1%20%D8%A8%D8%B1%20%D8%B1%D9%88%DB%8C%20%D9%BE%D8%B1%D9%88%DA%98%D9%87%E2%80%8C%D9%87%D8%A7%DB%8C%20%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C%20%D9%85%D8%AE%D8%AA%D9%84%D9%81%20%D8%A7%D8%B3%D8%AA.%20%D8%A8%D8%A7%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%D8%8C%20%D9%85%DB%8C%E2%80%8C%D8%AE%D9%88%D8%A7%D9%87%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%A7%D9%87%D8%AF%D8%A7%D9%81%20%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%D8%AE%D9%88%D8%AF%20%D9%86%D8%B3%D8%A8%D8%AA%20%D8%A8%D9%87%20%D8%A7%D8%B1%D8%AA%D9%82%D8%A7%20%D8%AC%D8%A7%D9%85%D8%B9%D9%87%20%D8%A8%D9%BE%D8%B1%D8%AF%D8%A7%D8%B2%DB%8C%D9%85.%0A%0A%F0%9F%8C%90%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%3A%20www.hafcin.ir%0A%0A%D8%A8%D8%A7%20%D9%85%D8%A7%20%D9%87%D9%85%D8%B1%D8%A7%D9%87%20%D8%A8%D8%A7%D8%B4%DB%8C%D8%AF%20%D9%88%20%D8%A8%D9%87%20%D9%87%D9%85%D8%AF%D9%84%DB%8C%20%D8%A8%D8%B1%D8%A7%DB%8C%20%D8%A8%D9%87%D8%A8%D9%88%D8%AF%20%D8%AF%D9%86%DB%8C%D8%A7%20%DA%A9%D9%85%DA%A9%20%DA%A9%D9%86%DB%8C%D9%85.%20%D8%B3%D9%BE%D8%A7%D8%B3%20%D8%A7%D8%B2%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%21%0A%0A%23%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%23%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%23%D8%AA%D8%AD%D9%88%D9%84_%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C');
            } else if (type === "bale") {
                window.open('https://bale.ai/share/url?url=%D8%A8%D8%A7%20%D8%B3%D9%84%D8%A7%D9%85%20%D8%AF%D9%88%D8%B3%D8%AA%D8%A7%D9%86%20%D8%B9%D8%B2%DB%8C%D8%B2%D8%8C%0A%0A%D8%A8%D8%A7%20%D8%A7%D9%81%D8%AA%D8%AE%D8%A7%D8%B1%20%D8%A8%D9%87%20%D8%B4%D9%85%D8%A7%20%D8%AF%D8%B9%D9%88%D8%AA%20%D9%85%DB%8C%E2%80%8C%DA%A9%D9%86%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%AC%D9%85%D8%B9%20%D9%85%D8%A7%20%D8%AF%D8%B1%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%D8%A8%D9%BE%DB%8C%D9%88%D9%86%D8%AF%DB%8C%D8%AF.%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%DB%8C%DA%A9%20NGO%20%D9%81%D8%B9%D8%A7%D9%84%20%D8%A7%D8%B3%D8%AA%20%DA%A9%D9%87%20%D8%A7%D8%B2%20%D8%B7%D8%B1%DB%8C%D9%82%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%20www.hafcin.ir%20%D8%AF%D8%B1%20%D8%AD%D8%A7%D9%84%20%DA%A9%D8%A7%D8%B1%20%D8%A8%D8%B1%20%D8%B1%D9%88%DB%8C%20%D9%BE%D8%B1%D9%88%DA%98%D9%87%E2%80%8C%D9%87%D8%A7%DB%8C%20%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C%20%D9%85%D8%AE%D8%AA%D9%84%D9%81%20%D8%A7%D8%B3%D8%AA.%20%D8%A8%D8%A7%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%D8%8C%20%D9%85%DB%8C%E2%80%8C%D8%AE%D9%88%D8%A7%D9%87%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%A7%D9%87%D8%AF%D8%A7%D9%81%20%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%D8%AE%D9%88%D8%AF%20%D9%86%D8%B3%D8%A8%D8%AA%20%D8%A8%D9%87%20%D8%A7%D8%B1%D8%AA%D9%82%D8%A7%20%D8%AC%D8%A7%D9%85%D8%B9%D9%87%20%D8%A8%D9%BE%D8%B1%D8%AF%D8%A7%D8%B2%DB%8C%D9%85.%0A%0A%F0%9F%8C%90%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%3A%20www.hafcin.ir%0A%0A%D8%A8%D8%A7%20%D9%85%D8%A7%20%D9%87%D9%85%D8%B1%D8%A7%D9%87%20%D8%A8%D8%A7%D8%B4%DB%8C%D8%AF%20%D9%88%20%D8%A8%D9%87%20%D9%87%D9%85%D8%AF%D9%84%DB%8C%20%D8%A8%D8%B1%D8%A7%DB%8C%20%D8%A8%D9%87%D8%A8%D9%88%D8%AF%20%D8%AF%D9%86%DB%8C%D8%A7%20%DA%A9%D9%85%DA%A9%20%DA%A9%D9%86%DB%8C%D9%85.%20%D8%B3%D9%BE%D8%A7%D8%B3%20%D8%A7%D8%B2%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%21%0A%0A%23%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%23%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%23%D8%AA%D8%AD%D9%88%D9%84_%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C');
            } else if (type === "instagram") {
                window.open('https://api.whatsapp.com/send?text=%D8%A8%D8%A7%20%D8%B3%D9%84%D8%A7%D9%85%20%D8%AF%D9%88%D8%B3%D8%AA%D8%A7%D9%86%20%D8%B9%D8%B2%DB%8C%D8%B2%D8%8C%0A%0A%D8%A8%D8%A7%20%D8%A7%D9%81%D8%AA%D8%AE%D8%A7%D8%B1%20%D8%A8%D9%87%20%D8%B4%D9%85%D8%A7%20%D8%AF%D8%B9%D9%88%D8%AA%20%D9%85%DB%8C%E2%80%8C%DA%A9%D9%86%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%AC%D9%85%D8%B9%20%D9%85%D8%A7%20%D8%AF%D8%B1%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%D8%A8%D9%BE%DB%8C%D9%88%D9%86%D8%AF%DB%8C%D8%AF.%20%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%DB%8C%DA%A9%20NGO%20%D9%81%D8%B9%D8%A7%D9%84%20%D8%A7%D8%B3%D8%AA%20%DA%A9%D9%87%20%D8%A7%D8%B2%20%D8%B7%D8%B1%DB%8C%D9%82%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%20www.hafcin.ir%20%D8%AF%D8%B1%20%D8%AD%D8%A7%D9%84%20%DA%A9%D8%A7%D8%B1%20%D8%A8%D8%B1%20%D8%B1%D9%88%DB%8C%20%D9%BE%D8%B1%D9%88%DA%98%D9%87%E2%80%8C%D9%87%D8%A7%DB%8C%20%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C%20%D9%85%D8%AE%D8%AA%D9%84%D9%81%20%D8%A7%D8%B3%D8%AA.%20%D8%A8%D8%A7%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%D8%8C%20%D9%85%DB%8C%E2%80%8C%D8%AE%D9%88%D8%A7%D9%87%DB%8C%D9%85%20%D8%A8%D9%87%20%D8%A7%D9%87%D8%AF%D8%A7%D9%81%20%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%D8%AE%D9%88%D8%AF%20%D9%86%D8%B3%D8%A8%D8%AA%20%D8%A8%D9%87%20%D8%A7%D8%B1%D8%AA%D9%82%D8%A7%20%D8%AC%D8%A7%D9%85%D8%B9%D9%87%20%D8%A8%D9%BE%D8%B1%D8%AF%D8%A7%D8%B2%DB%8C%D9%85.%0A%0A%F0%9F%8C%90%20%D9%88%D8%A8%D8%B3%D8%A7%DB%8C%D8%AA%3A%20www.hafcin.ir%0A%0A%D8%A8%D8%A7%20%D9%85%D8%A7%20%D9%87%D9%85%D8%B1%D8%A7%D9%87%20%D8%A8%D8%A7%D8%B4%DB%8C%D8%AF%20%D9%88%20%D8%A8%D9%87%20%D9%87%D9%85%D8%AF%D9%84%DB%8C%20%D8%A8%D8%B1%D8%A7%DB%8C%20%D8%A8%D9%87%D8%A8%D9%88%D8%AF%20%D8%AF%D9%86%DB%8C%D8%A7%20%DA%A9%D9%85%DA%A9%20%DA%A9%D9%86%DB%8C%D9%85.%20%D8%B3%D9%BE%D8%A7%D8%B3%20%D8%A7%D8%B2%20%D8%AD%D9%85%D8%A7%DB%8C%D8%AA%20%D8%B4%D9%85%D8%A7%21%0A%0A%23%D9%87%D9%81%D8%B3%DB%8C%D9%86%20%23%D8%AE%DB%8C%D8%B1%DB%8C%D9%87%20%23%D8%AA%D8%AD%D9%88%D9%84_%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%DB%8C');
            }
        }
    },
}
</script>

<style scoped>
    .help-us-content {
        width: 35%;
        padding: 15px 25px;
    }

    .help-us-box {
        background-color: var(--secondry-color);
        padding: 36px;
        border-radius: 10px;
        margin-top: 25px;
        position: relative;
    }

    .help-box-with-btn {
        padding: 35px 35px 90px 35px;
    }

    .card-number {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 5px 5px 0px 5px;
    }

    .card-number img {
        cursor: pointer;
    }

    .card-number p {
        letter-spacing: 8px;
    }


    .social-accounts {
        width: 40%;
        display: flex;
        justify-content: space-between;
    }

    .site-url {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        background: white;
        padding: 10px;
        border-radius: 10px;
    }
    
    .site-url p {
        background: var(--secondry-color);
        padding: 5px 10px;
        border-radius: 10px;
        letter-spacing: 0px;
        text-align: left;
    }

    .help-us-sidebar {
        width: 35%;
    }

    .help-us-inputs {
        width: 60%;
    }

    .help-us-content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }

    .input-row {
        display: flex;
        flex: row;
        margin: 15px 5px;
        padding-bottom: 15px;
        width: 100%;
    }

    .input {
        margin: 0px 5px;
        color: var(--secondry-purple);  
    }


    .submit-btn {        
        position: absolute;
        background: var(--secondry-purple);
        padding: 1.5vw 6vh;
        color: white;
                width: 132px;

        height: -moz-min-content;
        height: 100px;
        border-radius: 10px;
        position: absolute;
        font-size: 1.5rem;
    }

    button {
        background: none;
        outline: none;
        border: none;
        width: 50%;
        height: 50px;
        font-size: 2rem;

        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        text-decoration: none;

    }

    button span {
        font-size: 3rem;
        color: white;
        transform: rotate(180deg);
    } 

    @media screen and (max-width: 1500px) {
        .card-number p {
            letter-spacing: 4px;
        }
    }
    
    @media screen and (max-width: 1300px) {
        .card-number p {
            letter-spacing: 0px;
        }
    }

    @media screen and (max-width: 740px) {
        .help-us-content {
            flex-direction: column-reverse;
            width: 95%;
        }   

        .help-us-inputs {
            width: 100%;
        }

        .input-row {
            flex-direction: column;
        }

        .help-us-sidebar {
            width: 100%;
            margin-top: 25px;
        }

        .card-number {
            font-size: 20px;
            font-weight: 500;
            margin-top: 6px;
        }

        .share-box {
            display: flex;
            flex-direction: column-reverse;
        }

        .site-url {
            width: 100%;
        }

        .social-accounts {
            width: 110%;
            padding-top: 20px;
        }
    }
</style>