<template>
  <div class="campains">
    <MainHeader />
    <div class="campain-header">
      <div class="header-image">
        <img :src="defaultBanner" alt="banner" />
      </div>
      <div class="header-title">
        <h1>کمک برای ساختن زندگی های زیباتر</h1>
        <p>
          ما از طریق کمک ها و حمایت های شما زندگی زیباتری را به خانواده های تحت
          پوششمان هدیه می کنیم
        </p>
        <button class="submit-btn">
          حمایت
          <span class="material-symbols-outlined"> login </span>
        </button>
      </div>
    </div>

    <h1 class="section-title">کمپین‌های در جریان</h1>
    <CampaginsList style="width: 100%" />

    <h1 class="section-title" style="margin-top: 50px">گزارش</h1>
    <p v-if="!reports">خطا در دریافت اطلاعات</p>
    <div class="reports-list" v-if="reports">
      <div
        class="report-card"
        dir="rtl"
        :style="{
          backgroundImage: `url('${report.banner_image ?? defaultBanner}')`,
        }"
        v-for="(report, key) in reports"
        :key="'report-' + key"
      >
        <div class="report-btn" :title="report.title">
          <p>
            {{ report.title }}
          </p>
        </div>
      </div>
      <!--<div class="report-card" dir="rtl" :style="{backgroundImage: `url('${report.banner_image}')`}" v-for="(report, key) in reports" :key="key">
                <div class="report-btn">
                    {{ report.title }}
                </div>
            </div> -->
    </div>

    <div class="aye">
      <p class="matn-aye">
        مَثَلُ الَّذِينَ يُنْفِقُونَ أَمْوَالَهُمْ فِي سَبِيلِ اللَّهِ كَمَثَلِ
        حَبَّةٍ أَنْبَتَتْ سَبْعَ سَنَابِلَ فِي كُلِّ سُنْبُلَةٍ مِائَةُ حَبَّةٍ
        وَاللَّهُ يُضَاعِفُ لِمَنْ يَشَاءُ وَاللَّهُ وَاسِعٌ عَلِيمٌ ﴿۲۶۱﴾
      </p>
      <p class="tarjome">
        ‌مَثَلِ [انفاقاتِ] کسانى که اموالشان را در راه خدا انفاق مى‌کنند، مانند
        مَثَل دانه‌ سنبلى است که هفت خوشه برویاند و در هر خوشه صد دانه باشد
        (پاداش انفاق هفتصد برابر است) و خدا براى هر کس که بخواهد [پاداش را] چند
        برابر می‌کند، و خدا وسعت بخش داناست.
      </p>
      <p class="address-aye">سوره بقره؛ آیه ۲۶۱</p>
    </div>

    <div class="help-us">
      <h1 class="section-title">حمایت از ما</h1>
      <HelpUs />
    </div>

    <!-- Footer -->
    <mainFooter />
  </div>
</template>

<script>
import MainHeader from "../../components/header.vue";
import { getReports } from "./requests";
import mainFooter from "@/components/footer.vue";
import banner from "@/assets/images/defaultBanner.png";
// import progressbar from '@/components/progressbar.vue';
import HelpUs from "@/components/helpus.vue";
import CampaginsList from "@/components/campaginsList.vue";
export default {
  name: "CampainsPage",
  components: {
    MainHeader,
    mainFooter,
    // progressbar,
    HelpUs,
    CampaginsList,
  },
  data() {
    return {
      reports: [],
      defaultBanner: banner,
    };
  },
  beforeCreate() {
    getReports().then((res) => {
      this.reports = res?.data?.results;
    });
  },
};
</script>

<style scoped>
.campains {
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.campain-header {
  display: flex;
  align-items: stretch;
  padding: 15px;
  height: 80vh;
}

.header-image {
  width: 50%;
  min-width: 500px;
  height: min-content;
  /* height: 50vh; */
  background-color: gray;
  margin: 25px;
  border-radius: 10px;
}

.header-image img {
  width: 100%;
  height: 100%;
}

.header-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
  padding: 30px 15px;
  position: relative;
}

.header-title h1 {
  font-size: 60px;
  color: var(--secondry-purple);
  text-align: right;
  font-weight: 1000;
  width: 90%;
}

.header-title p {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  width: 95%;
  font-size: 30px;
  color: var(--text-purple);
}

p,
h1 {
  white-space: unset;
}

.submit-btn {
  position: absolute;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: -moz-min-content;
  width: 100px;
  height: -moz-min-content;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
  bottom: -3vh;
  left: 0;
  display: none; /* temp */
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 0.7rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}

/* .campains-list {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 20px 0px;
        overflow: auto;
    }

    .campain-card-data {
        background-color: var(--secondry-color);
        border-radius: 10px;
        width: 300px;
        height: 350px;
        min-width: 300px;
        min-height: 350px;
    }

    .campain-card {
        margin: 0px 15px;
    }

    .campain-card img {
        width: 100%;
        border-radius: 10px;
    }

    .campain-card-info {
        padding: 10px;
    }

    .card-action {
        position: relative;
        margin: 10px 0px 10px 20px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    } */

.section-title {
  color: var(--secondry-purple);
  font-size: 40px;
  font-weight: 900;
  padding-bottom: 40px;
}

.reports-list {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px 0px;
  /* overflow-y: auto; */
  /* flex-wrap: wrap; */
}

.report-card {
  /* min-width: 320px;
        min-height: 350px; */
  width: 320px;
  height: 350px;
  border-radius: 10px;
  /* margin: 0px 75px; */
  margin: 0px 40px;
  cursor: pointer;
  position: relative;
  background-size: cover;
}

.report-btn {
  padding: 25px 25px;
  border-radius: 10px;
  background: var(--secondry-purple);
  position: absolute;
  width: 90%;
  bottom: 20%;
  left: -50px;
  font-size: 25px;
  transition: 500ms;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
}

.report-btn p {
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
}

.report-card:hover > .report-btn {
  /* padding: 20px 30px; */
  font-size: 24px;
}

.aye {
  margin: 80px 0px;
  padding: 60px;
  border-radius: 10px;
  background-color: var(--secondry-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.matn-aye,
.tarjome {
  text-align: center;
}

.matn-aye {
  font-size: 30px;
  font-weight: 500;
  color: var(--secondry-purple);
}

.tarjome {
  font-size: 25px;
  font-weight: 500;
  margin-top: 60px;
}

.address-aye {
  color: var(--secondry-purple);
  font-size: 25px;
  font-weight: 500;
  margin-top: 10px;
}

.help-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help-us h1 {
  font-size: 40px;
}

@media screen and (max-width: 1055px) {
  .campains {
    padding: 0px 0px;
  }

  .report-card {
    margin-top: 15px;
  }
}

@media screen and (max-width: 740px) {
  .header-image {
    display: none;
  }

  .header-title {
    width: 100%;
  }

  .campain-header {
    padding: 20px 0px;
    height: min-content;
  }

  .header-title h1 {
    font-size: 40px;
    font-weight: 900;
  }

  .header-title p {
    margin-top: 6vh;
    font-size: 22px;
  }

  .submit-btn {
    display: block; /* temp */
    position: relative;
    width: max-content;
    display: flex;
    margin-top: 3vh;
    bottom: 0;
  }

  .campains-list {
    justify-content: center;
    flex-wrap: wrap;
  }

  .section-title {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .report-btn {
    padding: 15px 30px;
    font-size: 20px;
    left: -20px;
  }

  .aye {
    padding: 60px 30px;
    width: 70%;
  }

  .matn-aye {
    text-align: justify;
    text-wrap: pretty;
    font-size: 20px;
  }

  .tarjome {
    font-size: 16px;
    text-align: justify;
    text-wrap: pretty;
  }

  .address-aye {
    display: none;
  }

  .reports-list {
    flex-direction: column;
    align-items: end;
  }

  .report-card {
    margin: 5px 0 15px 0;
  }

  .report-btn {
    right: 20px;
  }
}
</style>
