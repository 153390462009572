var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"formBox",on:{"submit":function($event){$event.preventDefault();return _vm.submitMessage()}}},[_c('div',{staticClass:"userInfo"},[_c('TextInput',{attrs:{"label":"نام و نام خانوادگی","placeholder":"علی احمدی","options":{ small: true },"hasError":_vm.$v.name.$error,"error":"لطفا نام و نام خانوادگی خود را به فارسی وارد کنید"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('TextInput',{attrs:{"label":"شماره تماس","placeholder":"۰۹۳۵۶۱۰۲۵۱۰","options":{ small: true },"hasError":_vm.$v.phone.$error,"error":"شماره تلفن وارد شده معتبر نمی باشد"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('SelectInput',{attrs:{"label":"موضوع پیام","selectOptions":[
        { value: 'اشتغال', label: 'اشتغال' },
        { value: 'آموزش', label: 'اموزش' },
        { value: 'یادآور مهربانی', label: 'یادآور مهربانی' },
        { value: 'وام(احسان)', label: 'وام و طرح احسان' },
        { value: 'پزشکی', label: 'پزششکی و درمانی' },
        { value: 'مناسبتی', label: 'مناسباتی و فرهنگی' },
      ],"hasError":_vm.$v.subject.$error,"error":"لطفا موضوع را انتخاب کنید"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1),_c('div',{staticClass:"textarea"},[_c('label',[_vm._v("متن پیام")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],style:(_vm.$v.name.$error && { border: '1px solid red' }),attrs:{"dir":"rtl","cols":"30","rows":"10"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}}),(_vm.$v.name.$error)?_c('label',{staticClass:"message-error"},[_vm._v("لطفا این ورودی را با بیش از 10 کاراکتر پر کنید")]):_vm._e()]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"submit-btn"},[_vm._v(" ارسال "),_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" login ")])])
}]

export { render, staticRenderFns }