import {http} from '@/services/http'


export async function sendMessage(name, phone, subject, text) {
    const body = {       
        name: name,
        phone: "+98" + phone,
        type: subject,
        description: text
      }
      console.log(body)
    try {
      const res = http.post(`contactus/submit/`, body);
      return res
    }
    catch {
      return {}
    }
}