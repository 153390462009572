<template>
  <div class="register flex-col">
    <p v-if="!loggedIn">برای دادن تعهد نیاز است که وارد حساب کاربریتان شوید</p>

    <router-link to="/login">
      <button v-if="!loggedIn">ورود / عضویت</button>
    </router-link>

    <form v-if="loggedIn">!درحال حاضر این بخش در دسترس نمی باشد</form>
  </div>
</template>

<script>
import authService from "@/services/auth";
export default {
  name: "newTaahod",
  data() {
    return {
      loggedIn: false,
    };
  },
  beforeMount() {
    this.loggedIn = authService.isLoggedin();
  },
};
</script>

<style>
.register {
  padding: 25px 50px;
  border-radius: 10px;
  background-color: var(--secondry-color);
  height: 50vh;
  min-height: 300px;
  width: 50%;
  font-size: 1.5rem;
}

.register p {
  text-align: center;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  direction: rtl;
  font-weight: 600;
  font-size: 30px;
  width: 240px;
}

.register button {
  background: var(--secondry-purple);
  width: 240px;
  height: 100px;
  border: none;
  color: white;
  border-radius: 10px;
  margin: 20px 0px;
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
}

.register {
  padding: 0px 60px;
}

.register p {
  width: 100%;
}
</style>
