import LocalStorageManager from "./storage";
const userStorageManager = new LocalStorageManager('user_UID')

class userService {
    constructor(storage) {
        this.storage = storage;
    }

    getUID() {
        const data = this.storage.get();
        return data ? data : '';
    }

    setUID(uid) {
        if (!uid) {
          this.removeUID()
        }
        return this.storage.set(uid)
    }

    removeUID() {
        return this.storage.remove();
    }
}

export default new userService(userStorageManager);