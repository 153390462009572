<template>
  <div class="thisMonth">
    <div class="campagin-image" v-if="thisMonthCampain != null">
      <div
        class="image"
        :class="loading && 'loading-anim preimage'"
        :style="
          !loading && {
            backgroundImage: `url(${thisMonthCampain.banner_image})`,
          }
        "
      ></div>
      <button class="submit-btn" v-if="!loading">
        حمایت
        <span class="material-symbols-outlined"> login </span>
      </button>
    </div>
    <div class="info" v-if="thisMonthCampain != null">
      <h1>{{ thisMonthCampain.title }}</h1>
      <div class="data">
        <div class="data-box">
          <h2 v-if="!loading">مبلغ هدف</h2>
          <p v-if="!loading">{{ thisMonthCampain.target_fund }} تومان</p>
        </div>
        <div class="data-box">
          <h2 v-if="!loading">مبلغ جمع‌آوری شده</h2>
          <p v-if="!loading">{{ thisMonthCampain.total_fund }} تومان</p>
        </div>
      </div>
      <progressbar
        class="ProgressBar"
        :total_fund="thisMonthCampain.total_fund || 0"
        :target_fund="thisMonthCampain.target_fund || 100"
      />
    </div>
    <div class="notFound" v-if="thisMonthCampain == null">
      <p>در حال حاضر مهربانی وجود ندارد</p>
    </div>
  </div>
</template>

<script>
import progressbar from "@/components/progressbar.vue";
import { getThisMonthCampaign } from "../requests";
export default {
  name: "thisMonth",
  components: {
    progressbar,
  },
  data() {
    return {
      thisMonthCampain: {
        banner: "",
      },
      loading: true,
    };
  },
  beforeCreate() {
    getThisMonthCampaign().then((res) => {
      this.loading = false;
      if (!res.data) {
        this.thisMonthCampain = null;
        return;
      }
      this.thisMonthCampain = res;
    });
  },
};
</script>

<style scoped>
.thisMonth {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 150px;
}

.info {
  width: 50%;
}

.data-box {
  background-color: var(--secondry-color);
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1vw;
  width: 42.5%;
  min-height: 80px;
}

h2 {
  margin: 0px;
}

.campagin-image {
  width: 50%;
  margin: 0px 20px;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.submit-btn {
  position: absolute;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: -moz-min-content;
  width: 250px;
  height: -moz-min-content;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
  bottom: -3vh;
  right: 150px;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 0.7rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}

.ProgressBar {
  width: 100%;
  margin-top: 20px;
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
}

.notFound p {
  color: var(--secondry-purple);
}

@media screen and (max-width: 740px) {
  .notFound p {
    white-space: normal;
  }
}
</style>
