import LocalStorageManager from "./storage";
const tokenStorageManager = new LocalStorageManager('token')

class AuthService {
    constructor(storage) {
        this.storage = storage;
    }

    getToken() {
        const data = this.storage.get();
        return data ? data : '';
    }

    setToken(token) {
        if (!token) {
          this.removeToken()
        }
        return this.storage.set(token)
    }

    removeToken() {
        return this.storage.remove();
    }

    isLoggedin() {
        return Boolean(this.storage.get());
    }
}

export default new AuthService(tokenStorageManager);