<template>
    <div class="input">
      <label :v-for="id" v-if="label && !small" class="input__label">{{label}}</label>
      <label :v-for="id" v-if="label && small" class="input__label" style="font-size: 1.2rem;">{{label}}</label>
      <!-- Input had this too:      :options="options" -->
      <!-- <input
        class="input__controller"
        :placeholder="placeholder"
        :type="type"
        :id="id"
        :class="inputClass"
        @input="$emit('input', $event.target.value)"
      /> -->
      <select dir="rtl" class="input__controller" @change="$emit('input', $event.target.value)">
        <option value="none">انتخاب کنید</option>
        <option v-for="option in selectOptions" v-bind:key="option.value" :value="option.value">{{ option.label }}</option>
      </select>
      <p v-if="hasError" class="input__message input__message--error">
        {{ error }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TextInput',
    props: {
      value: String,
      small: {
        type: Boolean,
        default: () => false
      },
      selectOptions: {
        type: Array,
      },
      type: {
        type: String,
        default: () => 'text',
      },
      hasError: {
        type: Boolean,
        default: () => false,
      },
      error: {
        type: String,
        default: () => 'این ورودی را کنترل کنید',
      },
      customInputClass: {
        type: String
      },
      label: {
        type: String
      },
      id: {
        type: String
      }
    },
    computed: {
      inputClass() {
        return `${this.hasError ? 'input__controller--error' : ''} ${this.customInputClass} ${this.type ==='password' ? 'input_pass' : ''}`
      },
    },
  }
  </script>
  
  <style scoped>
  .input {
    display: flex;
    flex-direction: column;
  
    width: 100%;
    margin-bottom: 16px;
  
    color: var(--text-purple);
  }
  
  .input__controller {
    border: none;
    border-radius: 10px;
    padding: 15px;
    text-align: right;
    outline: none;
  
    box-sizing: border-box;
    font-weight: bold;
  
    font-size: 1rem;  
  }

  .input__controller option {
    padding: 15px;
    border-radius: 10px;
    border: none;
    margin: 40px;
  }
  
  .input_pass {
    /* -webkit-text-security: circle */
    font: small-caption;
    font-weight: bold;
  }
  
  .input__label {
    margin-bottom: 6px;
    text-align: right;
    font-size: 20px;
    font-weight: 500;
  }
  
  .input__message {
    font-size: 13px;
  }
  
  .input__controller--error {
    border: 1px solid red;
  }
  
  .input__message--error {
    color: darkred;
  }
  </style>
  