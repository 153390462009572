<template>
  <div class="dashboard-main">
    <MainHeader />
    <div class="dashboard">
      <DashboardSideBar
        :firstName="user.firstname"
        :lastName="user.lastname"
        :phone="user.phone"
        class="dashboard-sidebar"
        :activePage="tabIndex"
        :changeTab="changeTab"
      />
      <phoneController
        class="dashboard-phone-controller"
        :activePage="tabIndex"
        :changeTab="changeTab"
      />
      <div class="dashboard-content">
        <yadAvarMehrbani v-if="tabIndex !== 2" :tabIndex="tabIndex" />
        <editUser
          :firstname="user.firstname"
          :lastname="user.lastname"
          :phone="user.phone"
          :uid="user.uid"
          v-if="tabIndex === 2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "../../components/header.vue";
import DashboardSideBar from "./components/sidebar.vue";
import phoneController from "./components/phoneController.vue";
import yadAvarMehrbani from "./components/yadAvarMehrbani.vue";
import editUser from "./components/editUser.vue";
import EditUser from "./components/editUser.vue";
import { getUserData } from "./requests";
export default {
  name: "DashboardPage",
  data() {
    return {
      tabIndex: 2,
      user: {
        firstname: "",
        lastname: "",
        phone: "",
        uid: "",
      },
    };
  },
  components: {
    MainHeader,
    DashboardSideBar,
    yadAvarMehrbani,
    phoneController,
    editUser,
    EditUser,
  },
  methods: {
    changeTab(to) {
      this.tabIndex = to;
    },
  },
  beforeMount() {
    getUserData()
      .then(({ data }) => {
        console.log(data);
        if (data.first_name != "") {
          this.user.firstname = data.first_name;
        }
        this.user.lastname = data.last_name;
        if (data.phone_number != "") {
          this.user.phone = data.phone_number;
        }
        this.user.uid = data.uid;
      })
      .catch((err) => {
        console.log(err);
        this.$router.push("/");
      });
  },
};
</script>

<style>
.dashboard-main {
  padding: 30px 120px;
}

.dashboard {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 30px;
  align-items: center;
}

.dashboard-sidebar {
  width: 25vw;
}

.dashboard-phone-controller {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: none !important;
}

.dashboard-content {
  width: 75vw;
  padding: 0px 30px;
}

@media screen and (max-width: 1060px) {
  .dashboard-main {
    padding: 30px 5vw;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-sidebar {
    display: none;
  }

  .dashboard-phone-controller {
    display: flex !important;
  }
}
</style>
