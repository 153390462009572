import {http} from '@/services/http'


export async function getThisMonthCampaign() {
  try {
      const res = await http.get(`campaigns/monthly/`);
      return res.data;
  } catch (error) {
      if (error.response && error.response.status === 400) {
          // Handle 400 error, log, or show a custom error message
          console.error('Bad Request in getThisMonthCampaign:', error.response.data);
      } else if (error.response && error.response.status === 404) {
          return {};
      } else {
          // Handle other errors, log, or show a generic error message
          console.error('Error in getThisMonthCampaign:', error);
      }
      return {};
  }
}
  