<template>
  <div class="input">
    <label :v-for="id" v-if="label && !small" class="input__label">{{
      label
    }}</label>
    <label
      :v-for="id"
      v-if="label && small"
      class="input__label"
      style="font-size: 1.2rem"
      >{{ label }}</label
    >
    <!-- Input had this too:      :options="options" -->
    <input
      class="input__controller"
      :placeholder="placeholder"
      :type="type"
      :id="id"
      :class="inputClass"
      :autofocus="focused"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <p v-if="hasError" class="input__message input__message--error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    value: String,
    small: {
      type: Boolean,
      default: () => false,
    },
    placeholder: String,
    type: {
      type: String,
      default: () => "text",
    },
    hasError: {
      type: Boolean,
      default: () => false,
    },
    error: {
      type: String,
      default: () => "این ورودی را کنترل کنید",
    },
    customInputClass: {
      type: String,
    },
    label: {
      type: String,
    },
    id: {
      type: String,
    },
    focused: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    inputClass() {
      return `${this.hasError ? "input__controller--error" : ""} ${
        this.customInputClass
      } ${this.type === "password" ? "input_pass" : ""}`;
    },
  },
};
</script>

<style scoped>
.input {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 16px;

  color: var(--text-purple);
}

.input__controller {
  border: none;
  border-radius: 10px;
  padding: 15px;
  text-align: right;
  outline: none;

  box-sizing: border-box;
  font-weight: bold;

  font-size: 1rem;
}

.input_pass {
  /* -webkit-text-security: circle */
  font: small-caption;
  font-weight: bold;
}

.input__controller::placeholder {
  color: var(--secondry-purple);
  font-weight: 600;
}

.input__label {
  margin-bottom: 6px;
  text-align: right;
  font-size: 20px;
  font-weight: 500;
}

.input__message {
  font-size: 13px;
}

.input__controller--error {
  border: 1px solid red;
}

.input__message--error {
  color: darkred;
}
</style>
