import {http} from '@/services/http'
import userService from "@/services/user"

export async function getUserMehrbani() {
    try {
      const res = http.get(`users/`);
      return res
    }
    catch {
      return {}
    }
}

export async function getUserData() {
  try {
    const res = http.get(`accounts/users/${userService.getUID()}/user_public_info/`);
    res.uid = userService.getUID();
    return res
  }
  catch {
    return {}
  }
}