<template>
  <div class="about-us">
    <MainHeader />
    <div class="aboutHeader">
      <img :src="GroupImage" />
      <div class="info">
        <h1>درباره ما</h1>
        <p>
          هفسین یه گروه نیکوکاریه که در زمینه های مختلف قدم هایی برای حل مشکلات
          بر میداره. اینجا همه آماده کمک اند برای ساختن آینده ای بهتر
        </p>
      </div>
    </div>
    <br />
    <p class="text">
      اینجا چند تا واحد داریم که از زمان تشکیل شدنشون به طور پیوسته فعالیت
      می‌کنن و در عین حال چندین پویش هم داریم که سالی یکبار انجام می‌شن.
    </p>

    <br />

    <h1 class="title">یادآور مهربانی</h1>
    <p class="text">
      هر ماه، مشکلات و موارد ضروری نیازمندان در قالب پروژه‌هایی که توسط رابط‌های
      مورد اعتمادمون معرفی شدن در کانال هفسین منتشر می‌شود. خیرین و همراهان
      همیشگی کمک های خودشون رو واریز می‌کنن و پس از انجام شدن هر مهربانی ما
      گزارش‌های مالی و تصویری رو باهاتون به اشتراک می‌گذاریم.
    </p>

    <br />

    <h1 class="title">طرح احسان</h1>
    <p class="text">
      کمک‌های شما در این واحد بلاعوض نیست و به صورت قرضیه که بعد از مدت تعیین
      شده بهتون باز گردونده میشه. اینجا به نیازمندان وام بدون بهره می‌دیم تا
      مشکلات ضروری‌شون رو برطرف کننند و یا کسب و کار‌شون رو احیا کنند. شرح
      مشکلات مددجوها مثل واحد یادآور مهربانی توسط رابط‌های مطمئن به ما رسونده
      میشه و بعد از بررسی به اون‌ها وام تعلق می‌گیره.
    </p>

    <br />

    <h1 class="title">هفصد</h1>
    <p class="text">
      اینجا همه چیز در مورد کاره دغدغه این واحد نوپا اشتغال مددجوهاست. از
      برگزاری کلاس‌های آموزشی و مهارت‌آموزی گرفته تا کمک به فروش محصولاتی که
      مددجو‌ها تولید میکنن. عروسک محصولیه که با اون کار رو شروع کردیم. اونها
      توسط خیاط‌هایی از قشر نیازمند تولید می‌شن و ما هم براشون تبلیغ می‌کنیم و
      به مغازه‌ها می‌سپریم تا فروش برن در ضمن درصدی از مبلغ هر عروسک هم صرف
      کارهای خیر میشه.
    </p>

    <br />

    <h1 class="title">خدمات پزشکی</h1>
    <p class="text">
      تمرکز این واحد بر سلامتی مددجوهاست. و هدفش هم ارائه خدمات درمانی و دارویی
      رایگان برای مناطق محروم و قشر کم درآمد جامعه است. <br />
      اینجا با کمک‌های داوطلبانه پزشکانی مجرب و مهربان و دانشجوهای پزشکی مشتاق و
      دغدغه‌مند، به روستاها و مناطق محروم سر میزنیم و بعد از معالجه و غربال
      بیماران دارو و درمان‌های لازم‌شون رو به‌طور رایگان فراهم می‌کنیم و البته
      وضعیت سلامتی آنها رو تا زمان بهبودی کامل دنبال می‌کنیم.
    </p>

    <br />

    <h1 class="title">پویش لوازم التحریر</h1>
    <p class="text">
      در این پویش هر سال حوالی شهریور ماه که بچه‌ها برای مدرسه رفتن آماده می‌شن،
      مبلغی رو به کمک هم جمع می‌کنیم و برای دانش آموزانی که خانواده‌هاشون توان
      خرید وسایل مدرسه و لوازم‌التحریر ندارن این لوازم رو می‌خریم و اهدا
      می‌کنیم.
    </p>

    <br />

    <h1 class="title">کتابت رو به ما بسپار</h1>
    <p class="text">
      هر ساله تعداد زیادی از کتاب‌های کمک درسی توسط کنکوری‌ها خریده می‌شه و بعد
      از کنکور بلا اسفاده رها می‌شن. در این پویش کنکور داده‌ها کتاب‌هاشون رو به
      ما می‌سپرن تا به دانش‌آموزان کنکوری که توان خریدشون رو ندارن اهدا کنیم.
      کتاب‌هایی هم که قابلیت استفاده مجدد نداشته باشند، بازیافت می‌شوند.
    </p>

    <br />

    <h1 class="title">چه کمکی از ما بر میاد؟</h1>
    <p class="text">
      ما با آغوشی گرم پذیرای هر گونه یاری از طرف شما هستیم. این کمک‌ها میتونه به
      صورت مالی، زمانی و یا حتی معرفی ما برای بیشتر دیده شدن و ترویج مهربانی
      باشه.
    </p>

    <br />

    <div class="box">
      <h1>حمایت از ما</h1>
      <Helpus />
    </div>

    <div class="box">
      <h1 style="margin: 60px 0px 10px 0px">کمپین‌های در جریان</h1>
      <CampaginsList />
    </div>

    <mainFooter />
  </div>
</template>

<script>
import GroupImage from "@/assets/images/Us.png";
import MainHeader from "../../components/header.vue";
import Helpus from "@/components/helpus.vue";
import CampaginsList from "@/components/campaginsList.vue";
import mainFooter from "@/components/footer.vue";
export default {
  name: "AboutUs",
  components: {
    MainHeader,
    Helpus,
    CampaginsList,
    mainFooter,
  },
  data() {
    return {
      GroupImage: GroupImage,
    };
  },
};
</script>

<style scoped>
.about-us {
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.aboutHeader {
  padding: 50px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.aboutHeader img {
  width: 50%;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
}

.info h1 {
  color: var(--secondry-purple);
  font-weight: 950;
  font-size: 60px;
  margin-top: 20px;
}

.info p {
  text-align: justify;
  text-wrap: pretty;
  font-size: 30px;
  font-weight: 600;
  width: 90%;
  margin-top: 60px;
  white-space: unset;
}

.text {
  font-size: 30px;
  width: 100%;
  text-wrap: pretty;
  white-space: unset;
  word-spacing: 0px;
  line-height: 40px;
  margin-top: 10px;
  font-weight: 500;
}

.title {
  color: var(--text-purple);
  font-weight: 900;
  font-size: 40px;
  margin-top: 50px;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.box h1 {
  font-size: 40px;
  font-weight: 900;
  margin: 60px 0px;
  color: var(--secondry-purple);
  /* margin-bottom: 10px; */
}

@media screen and (max-width: 1065px) {
  .about-us {
    padding: 0px 0px;
  }

  .info h1 {
    font-weight: 700;
  }

  .info p {
    font-weight: 500;
  }

  .aboutHeader {
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .aboutHeader .info {
    width: 80%;
  }

  .aboutHeader p {
    text-wrap: unset;
    width: 100%;
  }

  .aboutHeader img {
    width: 80%;
    margin-top: 50px;
  }

  .title {
    font-weight: 700;
  }

  .text {
    text-wrap: unset;
  }
}
</style>
