<template>
  <div class="edit-user">
    <div class="edit-user-inputs">
      <div class="edit-user-input-row">
        <TextInput
          class="edit-input"
          label="نام"
          placeholder="بدون نام"
          v-model="$v.firstname.$model"
          :hasError="$v.firstname.$error"
          error="این ورودی باید فارسی باشد"
          :value="firstname"
        />
        <TextInput
          class="edit-input"
          label="نام خانوادگی"
          placeholder="بدون نام خانوادگی"
          v-model="$v.lastname.$model"
          :hasError="$v.lastname.$error"
          error="این ورودی باید فارسی باشد"
          :value="lastname"
        />
      </div>
      <div class="edit-user-input-row">
        <TextInput
          class="edit-input"
          label="شماره تماس"
          placeholder="09********"
          v-model="$v.phone.$model"
          :hasError="$v.phone.$error"
          error="* شماره تلفن شما معتبر نمی باشد."
          :value="phone"
        />
        <!-- <TextInput class="edit-input" label="رمز عبور" placeholder="****" /> -->
        <!-- <TextInput class="edit-input" label="پست الکترونیک" placeholder="abc@gmail.com"/> -->
      </div>
      <p class="submitMessage" v-if="message != null && !error">
        {{ message }}
      </p>
      <p class="errorMessage" v-if="message != null && error">{{ message }}</p>
      <button class="submit-btn" @click="editProfile">
        ذخیره
        <span class="material-symbols-outlined"> login </span>
      </button>
    </div>

    <popupInput v-if="false" />
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import popupInput from "@/components/popupInput.vue";
import { updateUser } from "@/store/requests";
import { isPersian } from "@/utils/validators";
import { numeric } from "vuelidate/lib/validators";
import { isPhoneValid } from "@/utils/validators";
export default {
  name: "editUser",
  validations: {
    firstname: { isPersian },
    lastname: { isPersian },
    phone: { numeric, isPhoneValid },
  },
  props: {
    firstname: String,
    lastname: String,
    phone: String,
    uid: String,
  },
  data() {
    return {
      error: false,
      message: null,
    };
  },
  components: {
    TextInput,
    popupInput,
  },
  methods: {
    editProfile() {
      if (this.$v.$invalid) {
        this.message = "خطا";
        this.error = true;
        return;
      }
      updateUser({
        first_name: this.firstname,
        last_name: this.lastname,
        phone_number: this.phone,
      })
        .then(() => {
          this.error = false;
          this.message = "تغییرات با موفقیت اعمال شد";
        })
        .catch(() => {
          this.error = true;
          this.message = "خطا";
        });
    },
  },
};
</script>

<style scoped>
.edit-user {
  width: 100%;
  padding: 15px;
}

.edit-user-inputs {
  background-color: var(--secondry-color);
  border-radius: 10px;
  padding: 15px 15px 40px 15px;
  position: relative;
}

.edit-user-input-row {
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 0px;
}

.edit-input {
  margin: 0px 5px;
  color: var(--secondry-purple);
}

.submit-btn {
  position: absolute;
  bottom: -10%;
  left: 4vw;
  background: var(--secondry-purple);
  padding: 15px 80px;
  color: white;
  width: min-content;
  height: min-content;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 0.7rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 2.5rem;
  color: white;
  transform: rotate(180deg);
}

.submitMessage {
  color: green;
}

.errorMessage {
  color: rgb(255, 57, 57);
}

@media screen and (max-width: 768px) {
  .edit-user-input-row {
    flex-direction: column;
    padding: 0px;
  }

  .edit-user-input-row .input {
    padding: 25px 0px;
  }

  .submit-btn {
    bottom: -5%;
    padding: 15px 40px;
  }
}
</style>
