<template>
  <div class="dashboard-header">
    <HafcnLogo />
    <div dir="rtl" class="navs">
      <router-link to="/" class="nav">کمپین ها</router-link>
      <router-link to="/taahodMehrabni" class="nav">تعهد مهربانی</router-link>
      <router-link to="/reports" class="nav">گزارش ها</router-link>
      <router-link to="/about-us" class="nav">درباره ما</router-link>
      <router-link to="/contact-us" class="nav">ارتباط با ما</router-link>
    </div>
    <!-- <div class="profile">
            
        </div> -->
    <!-- <router-link class="profile" to="/dashboard">
            <span class="material-symbols-outlined">
                person
            </span>
        </router-link> -->

    <Transition name="fade">
      <div class="lost-focus" v-if="burgerMenu" @click="toggleBurgerMenu"></div>
    </Transition>
    <Transition name="left-to-right">
      <div class="burger-menu" v-if="burgerMenu">
        <span class="material-symbols-outlined" @click="toggleBurgerMenu"
          >exit_to_app</span
        >
        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/' && 'nav-active'"
        >
          <div class="selected-dot"></div>
          <router-link to="/" class="nav"> کمپین ها </router-link>
        </div>

        <div
          class="nav-option"
          :class="
            $router.currentRoute.path == '/taahodMehrabni' && 'nav-active'
          "
        >
          <div class="selected-dot"></div>
          <router-link to="/taahodMehrabni" class="nav"
            >تعهد مهربانی</router-link
          >
        </div>

        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/reports' && 'nav-active'"
        >
          <div class="selected-dot"></div>
          <router-link to="/reports" class="nav">گزارش ها</router-link>
        </div>

        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/about-us' && 'nav-active'"
        >
          <div class="selected-dot"></div>
          <router-link to="/about-us" class="nav">درباره ما</router-link>
        </div>

        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/contact-us' && 'nav-active'"
        >
          <div class="selected-dot"></div>
          <router-link to="/contact-us" class="nav">ارتباط با ما</router-link>
        </div>

        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/dashboard' && 'nav-active'"
          v-if="loggedIn"
        >
          <div class="selected-dot"></div>
          <router-link to="/dashboard" class="nav">پنل کاربری</router-link>
        </div>

        <div
          class="nav-option"
          :class="$router.currentRoute.path == '/dashboard' && 'nav-active'"
          v-if="!loggedIn"
        >
          <div class="selected-dot"></div>
          <router-link to="/login" class="nav">ورود / عضویت</router-link>
        </div>
      </div>
    </Transition>

    <div class="buttons">
      <router-link class="profile" to="/login" v-if="!loggedIn">
        <p>ورود / عضویت</p>
      </router-link>

      <router-link class="profile" to="/dashboard" v-if="loggedIn">
        <span class="material-symbols-outlined">person</span>
      </router-link>

      <div dir="rtl" class="burger-menu-btn" @click="toggleBurgerMenu">
        <span class="material-symbols-outlined"> menu </span>
      </div>
    </div>
  </div>
</template>

<script>
import HafcnLogo from "@/components/logo.vue";
import authService from "@/services/auth";

export default {
  name: "MainHeader",
  data() {
    return {
      burgerMenu: false,
      loggedIn: false,
    };
  },
  components: {
    HafcnLogo,
  },
  methods: {
    toggleBurgerMenu() {
      this.burgerMenu = !this.burgerMenu;
    },
  },
  beforeMount() {
    this.loggedIn = authService.isLoggedin();
  },
};
</script>

<style scoped>
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}

.navs {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: IRANYekanX;
  font-weight: 600;
}

.profile,
.burger-menu-btn {
  display: flex;
  padding: 25px 40px;
  background-color: var(--secondry-purple);
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  margin: 0px 5px;
}

.burger-menu-btn {
  padding: 22px 22px 12px 22px;
}

.profile span,
p {
  color: white;
  font-size: 20px;
}

.profile span {
  font-size: 2em;
}

.burger-menu-btn span {
  color: white;
  font-size: 1.6rem;
}

.nav {
  text-decoration: none;
  margin: 0px 0.8vw;
  font-size: 1.2rem;
}

.burger-menu-btn {
  display: none;
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.burger-menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 75%;
  z-index: 99;
  background-color: var(--secondry-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selected-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--secondry-purple);
}

@media screen and (max-width: 860px) {
  .nav {
    font-size: 1rem;
  }
}

@media screen and (max-width: 740px) {
  .nav {
    display: none;
  }

  .burger-menu {
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 25px 0px 0px 25px;
  }

  .burger-menu .nav {
    display: block;
    font-size: 8vw;
    color: var(--text-purple);
  }

  .nav-option {
    display: flex;
    align-items: center;
    margin: 30px 0px;
  }

  .nav-option:first-of-type {
    margin-top: 50px;
  }

  .selected-dot {
    display: none;
  }

  .nav-active .nav {
    margin-left: 25px;
  }

  .nav-active .selected-dot {
    display: block;
  }

  .nav-active .nav {
    color: var(--secondry-purple);
  }

  .burger-menu span {
    transform: rotate(180deg);
    padding: 15px;
    border-radius: 10px;
    color: white;
    background-color: var(--secondry-purple);
  }

  .burger-menu-btn {
    display: block;
    padding: 12px 12px 2px 12px;
  }

  .profile {
    width: max-content;
    font-size: 0.8rem;
    padding: 8px 12px 8px 12px;
  }

  .profile span {
    display: block;
    font-size: 30px;
  }

  .profile {
    display: none;
  }
}
</style>
