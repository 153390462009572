<template>
  <div class="controller">
    <div
      class="item"
      :class="{ 'active-item': activePage == 0 }"
      @click="changeTab(0)"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3165 25.0168H20.5665C23.3665 25.0168 26.0998 23.2668 27.7498 20.4502L28.3832 19.3668L26.2165 18.1168L25.5832 19.2002C24.6332 20.8335 22.7998 22.6502 20.4332 22.5168C18.0998 22.6002 16.2832 20.8335 15.3332 19.2002L14.6999 18.1168L12.5332 19.3668L13.1665 20.4502C14.8165 23.2835 17.5665 25.0168 20.3165 25.0168Z"
          fill="#3C2E44"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9168 36.134L20.4168 36.3507L20.9168 36.134C28.7668 32.6507 37.1335 25.8173 37.0835 17.0673C37.0668 11.8839 34.2002 7.7506 29.6168 6.26727C26.1002 5.13394 22.8835 5.98394 20.4168 8.66727C17.9502 5.98394 14.7334 5.13394 11.2168 6.26727C6.63343 7.7506 3.76677 11.8839 3.7501 17.0673C3.71677 25.834 12.0834 32.6673 19.9168 36.134ZM11.9834 8.6506C12.7501 8.4006 13.4668 8.28394 14.1668 8.28394V8.26727C16.2001 8.26727 17.9502 9.31727 19.3835 11.3839L20.4168 12.8506L21.4502 11.3839C23.3835 8.61727 25.8835 7.68394 28.8502 8.6506C33.0502 10.0173 34.5835 13.9006 34.5835 17.084C34.6168 24.4507 27.0835 30.534 20.4168 33.6173C13.7501 30.534 6.21677 24.4507 6.2501 17.084C6.2501 13.9006 7.76677 10.0006 11.9834 8.6506Z"
          fill="#3C2E44"
        />
      </svg>

      <p style="font-size: 0.85rem">یادآور مهربانی</p>
    </div>

    <div
      class="item"
      :class="{ 'active-item': activePage == 1 }"
      @click="changeTab(1)"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3165 25.0168H20.5665C23.3665 25.0168 26.0998 23.2668 27.7498 20.4502L28.3832 19.3668L26.2165 18.1168L25.5832 19.2002C24.6332 20.8335 22.7998 22.6502 20.4332 22.5168C18.0998 22.6002 16.2832 20.8335 15.3332 19.2002L14.6999 18.1168L12.5332 19.3668L13.1665 20.4502C14.8165 23.2835 17.5665 25.0168 20.3165 25.0168Z"
          fill="#3C2E44"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9168 36.134L20.4168 36.3507L20.9168 36.134C28.7668 32.6507 37.1335 25.8173 37.0835 17.0673C37.0668 11.8839 34.2002 7.7506 29.6168 6.26727C26.1002 5.13394 22.8835 5.98394 20.4168 8.66727C17.9502 5.98394 14.7334 5.13394 11.2168 6.26727C6.63343 7.7506 3.76677 11.8839 3.7501 17.0673C3.71677 25.834 12.0834 32.6673 19.9168 36.134ZM11.9834 8.6506C12.7501 8.4006 13.4668 8.28394 14.1668 8.28394V8.26727C16.2001 8.26727 17.9502 9.31727 19.3835 11.3839L20.4168 12.8506L21.4502 11.3839C23.3835 8.61727 25.8835 7.68394 28.8502 8.6506C33.0502 10.0173 34.5835 13.9006 34.5835 17.084C34.6168 24.4507 27.0835 30.534 20.4168 33.6173C13.7501 30.534 6.21677 24.4507 6.2501 17.084C6.2501 13.9006 7.76677 10.0006 11.9834 8.6506Z"
          fill="#3C2E44"
        />
      </svg>

      <p>کمپین ها</p>
    </div>

    <div
      class="item"
      :class="{ 'active-item': activePage === 2 }"
      @click="changeTab(2)"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3165 25.0168H20.5665C23.3665 25.0168 26.0998 23.2668 27.7498 20.4502L28.3832 19.3668L26.2165 18.1168L25.5832 19.2002C24.6332 20.8335 22.7998 22.6502 20.4332 22.5168C18.0998 22.6002 16.2832 20.8335 15.3332 19.2002L14.6999 18.1168L12.5332 19.3668L13.1665 20.4502C14.8165 23.2835 17.5665 25.0168 20.3165 25.0168Z"
          fill="#3C2E44"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9168 36.134L20.4168 36.3507L20.9168 36.134C28.7668 32.6507 37.1335 25.8173 37.0835 17.0673C37.0668 11.8839 34.2002 7.7506 29.6168 6.26727C26.1002 5.13394 22.8835 5.98394 20.4168 8.66727C17.9502 5.98394 14.7334 5.13394 11.2168 6.26727C6.63343 7.7506 3.76677 11.8839 3.7501 17.0673C3.71677 25.834 12.0834 32.6673 19.9168 36.134ZM11.9834 8.6506C12.7501 8.4006 13.4668 8.28394 14.1668 8.28394V8.26727C16.2001 8.26727 17.9502 9.31727 19.3835 11.3839L20.4168 12.8506L21.4502 11.3839C23.3835 8.61727 25.8835 7.68394 28.8502 8.6506C33.0502 10.0173 34.5835 13.9006 34.5835 17.084C34.6168 24.4507 27.0835 30.534 20.4168 33.6173C13.7501 30.534 6.21677 24.4507 6.2501 17.084C6.2501 13.9006 7.76677 10.0006 11.9834 8.6506Z"
          fill="#3C2E44"
        />
      </svg>

      <p>ویرایش</p>
    </div>

    <div class="item" @click="() => this.$router.push('/')">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3165 25.0168H20.5665C23.3665 25.0168 26.0998 23.2668 27.7498 20.4502L28.3832 19.3668L26.2165 18.1168L25.5832 19.2002C24.6332 20.8335 22.7998 22.6502 20.4332 22.5168C18.0998 22.6002 16.2832 20.8335 15.3332 19.2002L14.6999 18.1168L12.5332 19.3668L13.1665 20.4502C14.8165 23.2835 17.5665 25.0168 20.3165 25.0168Z"
          fill="#3C2E44"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.9168 36.134L20.4168 36.3507L20.9168 36.134C28.7668 32.6507 37.1335 25.8173 37.0835 17.0673C37.0668 11.8839 34.2002 7.7506 29.6168 6.26727C26.1002 5.13394 22.8835 5.98394 20.4168 8.66727C17.9502 5.98394 14.7334 5.13394 11.2168 6.26727C6.63343 7.7506 3.76677 11.8839 3.7501 17.0673C3.71677 25.834 12.0834 32.6673 19.9168 36.134ZM11.9834 8.6506C12.7501 8.4006 13.4668 8.28394 14.1668 8.28394V8.26727C16.2001 8.26727 17.9502 9.31727 19.3835 11.3839L20.4168 12.8506L21.4502 11.3839C23.3835 8.61727 25.8835 7.68394 28.8502 8.6506C33.0502 10.0173 34.5835 13.9006 34.5835 17.084C34.6168 24.4507 27.0835 30.534 20.4168 33.6173C13.7501 30.534 6.21677 24.4507 6.2501 17.084C6.2501 13.9006 7.76677 10.0006 11.9834 8.6506Z"
          fill="#3C2E44"
        />
      </svg>

      <p>خانه</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "phoneController",
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
  props: {
    activePage: {
      type: Number,
      defualt: () => 0,
    },
    changeTab: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.controller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 15px 15px 15px;
  background: linear-gradient(
    rgba(0, 212, 255, 0) 0%,
    rgba(255, 255, 255, 1) 30%
  );
  z-index: 2;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.item p {
  text-wrap: balance;
  text-align: center;
  font-size: 0.9rem;
}

.active-item path {
  fill: var(--secondry-purple);
}

.active-item p {
  color: var(--secondry-purple);
}
</style>
