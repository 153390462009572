// http.js
import axios from 'axios';
import authService from '@/services/auth'

console.log("vue appi api base url: ", process.env.VUE_APP_API_BASE_URL)

export const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || "http://localhost:8000/",
    headers: {
        'Accept-Language': 'fa-ir',
        'Accept': 'application/json',
    }
});

http.interceptors.request.use((config) => {
    config.headers['Authorization'] = "Bearer " + authService.getToken();
    return config;
}, (err) => {
    return Promise.reject(err)
})

http.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        console.log("error.response >> ", error.response);

        if (error.response.status === 500 || error.response.status === 502) {
            console.log("internal server error", error.response.status);
            throw "خطای در سرور";
        } else if (error.response.status === 429) {
            console.log("429 throttled");
            throw "تعداد درخواست‌های شما محدود شده است";
        } else if (error.response.status === 401) {
            console.log("401 unauthorized");
            throw "لطفا برای ورود تلاش کنید";
        }
        const data = error.response.data;
        let errorObject = data.message ? data.message : data;
        errorObject = errorObject.detail ? errorObject.detail : errorObject;
        const errorText = Array.isArray(errorObject) ? errorObject[0] : errorObject;
        return Promise.reject(errorText);
    } else if (error.request) {
        console.log("error.request >> ", error.request);
        return Promise.reject("خطا در اتصال به سرور");
    } else {
        console.log("This should never happen. error >> ", error);
        return Promise.reject("خطا در نرم افزار");
    }
});
