import Vue from 'vue'
import Vuex from 'vuex'

import authService from '@/services/auth'
import userService from '@/services/user'

import { OTPvalidation } from './requests'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: authService.getToken(),
    uid: userService.getUID(),
    signUp: {
      isLoading: false,
      error: '',
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      authService.setToken(token)
    },
    setUID(state, uid) {
      state.uid = uid
      userService.setUID(uid)
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        OTPvalidation(data.code, data.phone).then((res) => {
          if (!res) {
            reject()
          }
          console.log(res.data)
          commit('setToken', res.data.token)
          commit('setUID', res.data.user.uid)
          resolve()
        })
          .catch((err) => {
            reject(err)
          })
      })
    },

  },
  getters: {
    isLoggedIn: (state) => state.token && authService.isLoggedin(),

  },
})
