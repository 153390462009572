<template>
  <div class="taahod-mehrbani">
    <MainHeader />
    <div class="header">
      <newTaahod />
      <div class="info flex-col">
        <h1 dir="rtl">
          تعهد برای مهربانی ماهانه
          <!-- <span>
            <svg
              width="68"
              height="68"
              viewBox="0 0 68 68"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.5382 42.5286H34.9633C39.7232 42.5286 44.3699 39.5536 47.1749 34.7653L48.2516 32.9236L44.5683 30.7986L43.4916 32.6403C41.8766 35.4169 38.7599 38.5053 34.7366 38.2786C30.7699 38.4203 27.6816 35.4169 26.0666 32.6403L24.99 30.7986L21.3066 32.9236L22.3833 34.7653C25.1883 39.5819 29.8632 42.5286 34.5382 42.5286Z"
                fill="#65467F"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.8586 61.4278L34.7086 61.7961L35.5586 61.4278C48.9036 55.5061 63.1269 43.8895 63.042 29.0145C63.0136 20.2027 58.1403 13.176 50.3486 10.6544C44.3703 8.7277 38.902 10.1727 34.7086 14.7344C30.5153 10.1727 25.0468 8.7277 19.0685 10.6544C11.2768 13.176 6.4035 20.2027 6.37517 29.0145C6.3185 43.9178 20.5418 55.5345 33.8586 61.4278ZM20.3718 14.706C21.6752 14.281 22.8935 14.0827 24.0835 14.0827V14.0544C27.5402 14.0544 30.5153 15.8394 32.952 19.3527L34.7086 21.846L36.4653 19.3527C39.7519 14.6494 44.0019 13.0627 49.0453 14.706C56.1853 17.0294 58.792 23.631 58.792 29.0428C58.8486 41.5661 46.042 51.9078 34.7086 57.1495C23.3752 51.9078 10.5685 41.5661 10.6252 29.0428C10.6252 23.631 13.2035 17.001 20.3718 14.706Z"
                fill="#65467F"
              />
            </svg>
          </span> -->
        </h1>
        <p>با دادن این تعهد، ماهانه مبلغی ثابت به پروژه‌های ما کمک می‌کنید.</p>
      </div>
    </div>

    <div class="this-month">
      <h1 class="title">مهربانی این ماه</h1>
      <thisMonth />
    </div>

    <div class="this-month-explain" dir="rtl">
      ما می‌دونیم شما اونقدر مهربونید که می‌تونید امید رو تو قلب دانش‌آموزان
      محروم ایجاد کنید. پس با ما همراه شید. <br />
      خیریه نیکان ماموت کمپین #مهر_مهربانی_میخواهد رو با هدف تهیه و توزیع حدود
      2500 بسته لوازم التحریر و کیف نو، مخصوص دانش‌آموزان مشتاق به تحصیل مناطق
      محروم کشور راه اندازی کرده و منتظر سخاوت همه شما عزیزان هستیم تا یه بار
      دیگه ثابت کنیم مردم ما توی مهربونی همتا ندارن. مبلغ میانگین هر بسته لوازم
      التحریر 400 هزار تومان است که شما با هر مبلغ می‌توانید از کودکان سرزمینمان
      حمایت کنید. هر برگ سبزی از سوی شما ارزشمند است.
    </div>

    <div class="help-us">
      <h1 class="title">کمک مازاد تعهد</h1>
      <HelpUs />
    </div>

    <div class="other-campagins">
      <h1 class="title">دیگر کمپین ها</h1>
      <CampaginsList />
    </div>

    <!-- Footer -->
    <mainFooter />
  </div>
</template>

<script>
import HelpUs from "@/components/helpus.vue";
import MainHeader from "../../components/header.vue";
import thisMonth from "./components/this-month.vue";
import CampaginsList from "@/components/campaginsList.vue";
import mainFooter from "../../components/footer.vue";
import newTaahod from "./components/newTaahod.vue";

export default {
  name: "TaahodMehrabani",
  components: {
    MainHeader,
    thisMonth,
    HelpUs,
    CampaginsList,
    mainFooter,
    newTaahod,
  },
};
</script>

<style scoped>
.taahod-mehrbani {
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 50px 0px;
  width: 100%;
}

.flex-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.info {
  text-align: right;
  width: 50%;
}

.info h1 {
  font-size: 60px;
  font-weight: 950;
  color: var(--secondry-purple);
}

.info p {
  text-align: justify;
  font-weight: 500;
  font-size: 30px;
  width: 90%;
  text-wrap: unset;
}

.this-month {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--secondry-purple);
  font-size: 2rem;
  padding-bottom: 40px;
}

.this-month-explain {
  margin-top: 100px;
  text-align: right;
  font-size: 30px;
  font-weight: 500;
}

.help-us,
.other-campagins {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

@media screen and (max-width: 1065px) {
  .taahod-mehrbani {
    padding: 0px 0px;
  }
}

@media screen and (max-width: 768px) {
  .taahod-mehrbani {
    padding: 0px;
  }

  .header {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }

  .info {
    width: 95%;
  }

  .info h1 {
    line-height: 4rem;
    font-size: 50px;
    font-weight: 650;
  }

  .info p {
    margin: 30px 0px 20px 0px;
    white-space: unset;
    text-align: unset;
  }

  button {
    padding: 15px 15px !important;
  }

  .this-month-explain {
    margin-top: 20px;
  }
}

@media screen and (max-width: 525px) {
}
</style>
