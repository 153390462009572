import {http} from '@/services/http'


export async function getCampains() {
    try {
      const res = await http.get(`campaigns/`)
      return res
    }
    catch (err) {
      if (!err.response) {
        console.log("Failed to request");
        return null
      }

      if (err.response.status === 404) {
        return {}
      } else {
        return null
      }
    }
}
