<template>
  <div class="helps-list">
    <p>چیزی برای نمایش وجود ندارد.</p>
    <!-- <div class="help" v-for="n in 10" v-bind:key="n">
            <div class="left">
                <div class="payment-info">
                    <p style="font-size: 15px;" v-if="tabIndex === 1" dir="rtl">23/مرداد/1402</p>
                    <p v-if="tabIndex === 0">کد کمک: 25453432</p>
                    <p v-if="tabIndex === 1">کد تراکنش: 25453432</p>
                </div>
               
                <button class="submit-btn">
                    پرداخت
                    <span class="material-symbols-outlined">
                        login
                    </span>
                </button>
            </div>

            <div class="right">
                <img src="https://t4.ftcdn.net/jpg/03/09/57/65/360_F_309576547_Jxmy5JOuRH3fbmaximA6wERriBEqxPW7.jpg" alt="">
                <div class="help-info">
                    <h1>نام کمک</h1>
                    <p style="font-size: 15px;" v-if="tabIndex === 0">سررسید: 23/مرداد/1402</p>
                    <p style="margin: 35px 0px 10px 0px; font-size: 1.1rem;" v-if="tabIndex === 0">مبلغ ماهانه: 30.000.000 میلیون تومان</p>
                    <p style="margin: 35px 0px 10px 0px; font-size: 1.2rem;" v-if="tabIndex === 1">30.000.000 میلیون تومان</p>
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  name: "yadAvarMehrbani",
  props: {
    tabIndex: {
      type: Number,
      default: () => 0,
    },
  },
};
</script>

<style scoped>
.helps-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.help {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: var(--secondry-color);
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 5vw;
  width: 50vw;
}

.left {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.payment-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.submit-btn {
  position: absolute;
  bottom: -6vw;
  left: 2vw;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: min-content;
  height: min-content;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
}

h1 {
  color: var(--secondry-purple);
}

p {
  font-weight: bold;
}

.right {
  width: 75%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.right img {
  width: 20%;
  border-radius: 10px;
}

.help-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 30px;
  text-align: right;
  width: 100%;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 0.7rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 2.5rem;
  color: white;
  transform: rotate(180deg);
}
</style>
