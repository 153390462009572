<template>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </template>
  
  <script>
  // import ProgressBar from '@/components/RouteProgress.vue';
  
  // export default {
  //   name: 'RoutesWithTransition',
  //   components: {
  //     ProgressBar,
  //   },
  export default {
    name: 'RoutesWithTransition',
  }
  </script>
  
  <style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }
  
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  </style>
  