<template>
  <div class="recentReport">
    <div v-if="!lastReport" class="notLoaded">
      <div class="preimage loading-anim"></div>
    </div>
    <div v-if="lastReport" class="loaded">
      <img :src="lastReport.banner_image" />
      <div class="info">
        <p dir="rtl" class="hashtag" style="color: var(--secondry-purple)">
          #جدیدترین_گزارش
        </p>
        <h1 dir="rtl">{{ lastReport.title }}</h1>
        <p dir="rtl" style="font-weight: 500">{{ lastReport.description }}</p>
      </div>
      <button class="submit-btn">
        خواندن
        <span class="material-symbols-outlined"> login </span>
      </button>
    </div>
  </div>
</template>

<script>
import { getReports } from "../../campains/requests";
export default {
  name: "recentReport",
  data() {
    return {
      lastReport: null,
    };
  },
  mounted() {
    getReports().then((res) => {
      if (res?.data == null) return;
      this.lastReport = res.data[2];
    });
  },
};
</script>

<style scoped>
.recentReport {
  width: 100%;
  height: min-content;
  padding: 25px;
}

.loaded {
  display: flex;
  flex-direction: row;
  line-height: 6vw;
  height: 80vh;
}

.loaded img {
  width: 50%;
}

.loaded h1 {
  color: var(--secondry-purple);
  font-size: 5vw;
  margin-top: 10%;
  font-weight: 950;
}

.loaded p {
  text-wrap: pretty;
  line-height: 50px;
  margin-top: 5%;
  font-size: 30px;
}

.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.submit-btn {
  position: absolute;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: -moz-min-content;
  width: 250px;
  height: -moz-min-content;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
  bottom: 12%;
  left: 45%;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 2rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}
</style>
