<template>
  <div class="dashboard-sidebar-main">
    <div class="dashboard-sidebar-content">
      <div class="dashboard-info-box">
        <div class="dashboard-edit-user">
          <span
            class="material-symbols-outlined"
            style="cursor: pointer"
            v-if="activePage !== 2"
            @click="changeTab(2)"
          >
            edit
          </span>
        </div>
        <div class="info-box-data">
          <h1 class="info-box-name">{{ firstName + " " + lastName }}</h1>
          <p class="info-box-other">{{ toFarsiNumber(phone) }}</p>
          <!-- <p class="info-box-other">aliyara.a.84@gmail.com</p> -->
        </div>
      </div>
      <div class="sidebar-navs">
        <div
          class="sidebar-nav"
          :class="{ 'active-nav': activePage == 0 }"
          @click="changeTab(0)"
        >
          <div class="active-nav-circle"></div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3165 25.0168H20.5665C23.3665 25.0168 26.0998 23.2668 27.7498 20.4502L28.3832 19.3668L26.2165 18.1168L25.5832 19.2002C24.6332 20.8335 22.7998 22.6502 20.4332 22.5168C18.0998 22.6002 16.2832 20.8335 15.3332 19.2002L14.6999 18.1168L12.5332 19.3668L13.1665 20.4502C14.8165 23.2835 17.5665 25.0168 20.3165 25.0168Z"
              fill="#3C2E44"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.9168 36.134L20.4168 36.3507L20.9168 36.134C28.7668 32.6507 37.1335 25.8173 37.0835 17.0673C37.0668 11.8839 34.2002 7.7506 29.6168 6.26727C26.1002 5.13394 22.8835 5.98394 20.4168 8.66727C17.9502 5.98394 14.7334 5.13394 11.2168 6.26727C6.63343 7.7506 3.76677 11.8839 3.7501 17.0673C3.71677 25.834 12.0834 32.6673 19.9168 36.134ZM11.9834 8.6506C12.7501 8.4006 13.4668 8.28394 14.1668 8.28394V8.26727C16.2001 8.26727 17.9502 9.31727 19.3835 11.3839L20.4168 12.8506L21.4502 11.3839C23.3835 8.61727 25.8835 7.68394 28.8502 8.6506C33.0502 10.0173 34.5835 13.9006 34.5835 17.084C34.6168 24.4507 27.0835 30.534 20.4168 33.6173C13.7501 30.534 6.21677 24.4507 6.2501 17.084C6.2501 13.9006 7.76677 10.0006 11.9834 8.6506Z"
              fill="#3C2E44"
            />
          </svg>

          <p>یادآور مهربانی</p>
        </div>
        <div
          class="sidebar-nav"
          :class="{ 'active-nav': activePage == 1 }"
          @click="changeTab(1)"
        >
          <div class="active-nav-circle"></div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.51221 4.40552H17.6788V18.5722H3.51221V4.40552ZM6.01221 6.90552V16.0722H15.1789V6.90552H6.01221Z"
              fill="#3C2E44"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.9191 2.81357L37.6031 6.48017L33.9364 20.1642L20.2524 16.4975L23.9191 2.81357ZM25.6869 5.87543L23.3144 14.7298L32.1686 17.1023L34.5413 8.24794L25.6869 5.87543Z"
              fill="#3C2E44"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.51221 22.7388H17.6788V36.9055H3.51221V22.7388ZM6.01221 25.2388V34.4055H15.1789V25.2388H6.01221Z"
              fill="#3C2E44"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.8457 22.7388H36.0124V36.9055H21.8457V22.7388ZM24.3457 25.2388V34.4055H33.5124V25.2388H24.3457Z"
              fill="#3C2E44"
            />
          </svg>

          <p>کمپین های مشارکت شده</p>
        </div>
      </div>
    </div>

    <div class="dashboard-sidebar-content" style="margin-top: 15px">
      <div class="sidebar-nav exit" @click="logout(0)">
        <div class="active-nav-circle"></div>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.0689 19.1662L35.8652 19.1662L35.8652 21.6662L12.0689 21.6662L12.0689 19.1662Z"
            fill="#3C2E44"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.3776 13.1907L37.6357 20.4167L30.3779 27.6447L28.6138 25.8733L34.0926 20.4168L28.6138 14.9624L30.3776 13.1907Z"
            fill="#3C2E44"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9258 3.75L22.9258 13.9583L20.4258 13.9583L20.4258 6.25L5.38578 6.25L5.38578 34.5833L20.4258 34.5833L20.4258 26.875L22.9258 26.875L22.9258 37.0833L2.88578 37.0833L2.88578 3.75L22.9258 3.75Z"
            fill="#3C2E44"
          />
        </svg>

        <p>خروج</p>
      </div>
    </div>
  </div>
</template>

<script>
import { toFarsiNumber } from "@/utils/persian_numbers";
export default {
  name: "DashboardSideBar",
  methods: {
    toFarsiNumber,
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
  props: {
    activePage: {
      type: Number,
      defualt: () => 0,
    },
    changeTab: {
      type: Function,
    },
    firstName: String,
    lastName: String,
    phone: String,
  },
};
</script>

<style>
.dashboard-sidebar-main {
  background-color: none;
}

.dashboard-sidebar-content {
  background-color: var(--secondry-color);
  padding: 5px;
  border-radius: 10px;
  height: min-content;
}

.dashboard-info-box {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
}

.dashboard-info-box span {
  margin-top: 35%;
}

.dashboard-edit-user {
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
}

.info-box-data {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
}

.info-box-name {
  color: var(--secondry-purple);
  font-weight: bold;
}

.sidebar-nav {
  padding: 10px;
}

.sidebar-nav {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.sidebar-nav p {
  margin-right: 15px;
}

.sidebar-nav svg {
  margin-right: 2px;
}

.active-nav-circle {
  width: 5px;
  height: 5px;
  border-radius: 100%;
}

.active-nav p {
  color: var(--secondry-purple);
}

.active-nav path {
  fill: var(--secondry-purple);
}

.active-nav .active-nav-circle {
  background-color: var(--secondry-purple);
}

/* .sidebar-nav {
        transition: 250ms;
    } */

.sidebar-nav:hover p {
  color: var(--secondry-purple);
}

.sidebar-nav:hover path {
  fill: var(--secondry-purple);
}

.exit:hover p {
  color: rgb(154, 0, 0);
}

.exit:hover path {
  fill: rgb(154, 0, 0);
}
/* .sidebar-nav:hover .active-nav-circle {
        background-color: var(--secondry-purple);
        transition: 250ms;
    } */

p,
h1 {
  margin: 0px;
}
</style>
