<template>
  <div>
    <p v-if="!campagins">خطا در دریافت اطلاعات</p>
    <div class="campains-list" v-if="campagins">
      <div
        class="campain-card"
        v-for="(campain, key) in campagins"
        :key="'campagin-' + key"
      >
        <div class="campain-card-data">
          <div class="campagin-card-top">
            <div
              class="campagin-card-top-img"
              :style="{
                backgroundImage: `url('${
                  campain.banner_image ?? defaultBanner
                }')`,
              }"
            ></div>
            <!-- <img :src="campain.banner_image" alt=""> -->
            <!-- <img src="https://tino.ir/wp-content/uploads/2021/08/2-min-1.jpg" alt=""> -->
            <p class="campagin-card-title" :title="campain.title">
              {{ campain.title }}
            </p>
          </div>
          <p class="campagin-card-desc">
            {{
              String(Math.trunc(Number(campain.target_fund))).replace(
                /(\d)(?=(\d\d\d)+(?!\d))/g,
                "$1,"
              ) + " تومان"
            }}
          </p>
        </div>
        <div class="card-action">
          <button class="submit-btn">
            <span class="material-symbols-outlined"> login </span>
          </button>
          <!-- <div class="progressbar">
                        <div class="progressbar-bar" :style="{width: (campain.total_fund * 100) / campain.target_fund + '%'}">
                            {{ Math.floor((campain.total_fund * 100) / campain.target_fund) }}%
                        </div>
                    </div> -->
          <progressbar
            :target_fund="Number(campain.target_fund)"
            :total_fund="Number(campain.total_fund)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCampains } from "@/services/generalAPIrequests";
import progressbar from "./progressbar.vue";
import banner from "@/assets/images/defaultBanner.png";
export default {
  name: "CampaginsList",
  components: {
    progressbar,
  },
  data() {
    return {
      campagins: {},
      defaultBanner: banner,
    };
  },
  mounted() {
    getCampains().then((res) => {
      this.campagins = res?.data?.results;
    });
  },
};
</script>

<style scoped>
.submit-btn {
  background: var(--secondry-purple);
  padding: 1vw 5.5vh;
  color: white;
  width: -moz-min-content;
  width: 100px;
  height: -moz-min-content;
  height: 80px;
  border-radius: 10px;
  font-size: 1.5rem;
  bottom: -3vh;
  left: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  height: 50px;
  font-size: 0.7rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}

.campains-list {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px 0px;
  overflow: auto;
}

.campain-card-data {
  background-color: var(--secondry-color);
  border-radius: 10px;
  width: 390px;
  height: 500px;
  min-width: 300px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.campain-card {
  margin: 0px 15px;
}

.campain-card img {
  width: 100%;
  border-radius: 10px;
}

.campagin-card-top {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.campagin-card-top-img {
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.campagin-card-title,
.campagin-card-desc {
  margin: 15px;
}

.campagin-card-title {
  font-weight: 700;
  font-size: 25px;
  margin-top: 10%;
}

.campagin-card-desc {
  font-weight: 550;
  font-size: 20px;
}

.card-action {
  position: relative;
  margin: 10px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .campain-card {
    margin: 3vh 0px;
    width: 95%;
  }

  .campain-card-data {
    width: 100%;
  }

  .campains-list {
    flex-direction: column;
  }
}
</style>
