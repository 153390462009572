<template>
    <div class="progressbar">
        <!-- {{ target_fund }} -->
        <div class="progressbar-bar" :style="{width: (total_fund * 100) / target_fund + '%'}">
            <p>{{ Math.floor((total_fund * 100) / target_fund) }}%</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        total_fund: Number,
        target_fund: Number,
    }
}
</script>

<style>
    .progressbar {
        background: var(--main-purple);
        width: calc(100% - 113px);
        height: 44px;
        border-radius: 8px;
        display: flex;
        flex-direction: row-reverse;
    }

    .progressbar-bar {
        background-color: var(--secondry-purple);
        min-width: max-content;
        width: 10%;
        height: 24px;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: center; */
        justify-content: flex-start;
    }

    .progressbar-bar p {
        color: white;
        width: 100%;
        text-align: center;
    }
</style>