class LocalStorageManager {
    constructor(key) {
        if (!key) {
            throw new Error("Failed to require LocalStorageManager! key parameter is required");
        }
        this.key = key;
    }

    get() {
        const data = localStorage.getItem(this.key);
        return data;
    }

    set(data) {
        if (!data) return;
        localStorage.setItem(this.key, data);
    }
    
    remove() {
        localStorage.removeItem(this.key)
    }
}

export default LocalStorageManager;