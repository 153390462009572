<template>
  <div class="reports">
    <div v-if="loading" class="notLoaded">
      <div class="image loading-anim"></div>
      <div class="image loading-anim"></div>
      <div class="image loading-anim"></div>
      <div class="image loading-anim"></div>
    </div>

    <p v-if="!loading && reports != null">چیزی پیدا نکردیم !</p>

    <div v-if="reports" class="loaded">
      <div
        v-for="(report, key) in reports"
        class="image report"
        :key="key"
        :style="{ backgroundImage: `url(${report.banner_image})` }"
      >
        <button dir="rtl" class="submit-btn">
          {{ report.title }}
          <span class="material-symbols-outlined"> login </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getReports } from "../../campains/requests";
export default {
  name: "otherReports",
  data() {
    return {
      reports: null,
      loading: true,
    };
  },
  mounted() {
    getReports().then((res) => {
      this.loading = false;
      if (res.data == null) return;
      this.reports = res.data;
    });
  },
};
</script>

<style scoped>
.reports,
.notLoaded,
.loaded {
  width: 100%;
}

.notLoaded,
.loaded {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loading-anim {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f1f1f1 38%, #fafafa 54%);
  background-size: 1000px 640px;

  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.image {
  width: 45%;
  height: 350px;
  margin: 20px 0px;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.submit-btn {
  position: absolute;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: -moz-min-content;
  width: 250px;
  height: -moz-min-content;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
  bottom: 10%;
  left: -10%;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}
</style>
