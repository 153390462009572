<template>
  <div class="mainContent">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/gh/rastikerdar/vazirmatn@v33.003/Vazirmatn-font-face.css"
      rel="stylesheet"
      type="text/css"
    />
    <!-- <NotificationsContainer /> -->
    <!-- <FullPageLoading /> -->
    <!-- <Header /> -->
    <RoutesWithTransition />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import RoutesWithTransition from "@/router/RoutesWithTransition.vue";

export default {
  components: {
    RoutesWithTransition,
  },
};
</script>

<style>
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.mainContent {
  width: 100%;
}
</style>
