<template>
  <div class="contact-us">
    <MainHeader />
    <div class="info box">
      <h1 style="font-size: 60px; font-weight: 950">ارتباط با ما</h1>
      <p>
        هفسین یه گروه نیکوکاریه که در زمینه های مختلف قدم هایی برای حل مشکلات بر
        میداره. اینجا همه آماده کمک اند برای ساختن آینده ای بهتر
      </p>
    </div>

    <div class="contacts box">
      <h1>راه های ارتباطی</h1>
      <div class="contact-options">
        <div class="contact-option first-option">
          <span class="material-symbols-outlined"> location_on </span>
          <p>شهرک غرب، بلوار خوردین،‌ توحید دوم، پلاک ۲۱،‌ واحد ۸</p>
        </div>
        <div class="contact-option second-option">
          <span class="material-symbols-outlined"> call </span>
          <p>۰۹۳۵۶۱۰۲۵۱۰</p>
        </div>
        <div class="contact-option first-option">
          <span class="material-symbols-outlined"> calendar_month </span>
          <p>شنبه تا چهارشنبه: ۱۱ الی ۱۷</p>
        </div>
        <div class="contact-option second-option">
          <span class="material-symbols-outlined"> mail </span>
          <p>hafcin@gmail.com</p>
        </div>
      </div>
    </div>

    <div class="contact-form box">
      <h1>فرم تماس</h1>
      <ContactForm />
    </div>

    <div class="campagins box">
      <h1>کمپین های در جریان</h1>
      <campaginsList />
    </div>

    <Footer />
  </div>
</template>

<script>
import MainHeader from "../../components/header.vue";
import ContactForm from "../ContactUs/components/ContactForm.vue";
import campaginsList from "@/components/campaginsList.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "ContactUs",
  components: {
    MainHeader,
    ContactForm,
    campaginsList,
    Footer,
  },
};
</script>

<style scoped>
.box {
  margin-top: 4vh;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.box h1 {
  font-size: 40px;
  font-weight: 900;
  color: var(--secondry-purple);
  margin: 30px 0px;
}

.box p {
  font-size: 30px;
  margin-top: 25px;
  text-align: justify;
  text-wrap: auto;
  width: 95%;
}

.contact-us {
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info {
  width: 100%;
}

.contacts {
  width: 100%;
}

.contact-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
}

.contact-option {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  margin: 5px 0px;
}

.first-option {
  flex: 0 0 50%;
}

.second-option {
  padding-right: 20px;
}

.contact-option span {
  margin-left: 10px;
  color: var(--secondry-purple);
  font-size: 30px;
}

.contact-option p {
  font-size: 30px;
  margin-top: 25px;
  text-align: justify;
}

.contact-form {
  width: 100%;
}

.campagins {
  margin-top: 100px;
  align-items: center;
}

@media screen and (max-width: 1065px) {
  .contact-us {
    padding: 0px 0px;
  }

  .box p {
    font-size: 3vw;
  }

  .contact-option p {
    font-size: 2.8vw;
  }
}
</style>
