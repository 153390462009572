<template>
  <div class="notFound">
    <MainHeader />
    <div class="text">
      <h1>404</h1>
      <p dir="rtl">صفحه مورد نظر پیدا نشد!</p>
    </div>
    <div style="height: 20%"></div>
  </div>
</template>

<script>
import MainHeader from "@/components/header.vue";
export default {
  name: "NotFound",
  components: {
    MainHeader,
  },
};
</script>

<style scoped>
.notFound {
  padding: 30px 120px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound h1 {
  color: var(--secondry-purple);
  font-size: 40vw;
}

.notFound p {
  font-size: 2rem;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text p {
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .notFound {
    padding: 5px;
  }
}
</style>
