import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/store'

import LoginPage from '@/views/Login/index.vue'
import RegisterPage from '@/views/register/index.vue'
import DashboardPage from '@/views/Dashboard/index.vue'
import CampainsPage from '@/views/campains/index.vue'
import TaahodMehrabani from '@/views/TaahodMehrabani/index.vue'
import ContactUs from '@/views/ContactUs/index.vue'
import AboutUs from '@/views/aboutUs/index.vue'
import ReportsPage from '@/views/reports/index.vue'

import NotFound from '@/views/404/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage
  },
  {
    path: '/dashboard',
    name: 'DashboardPage',
    component: DashboardPage
  },
  {
    path: '/',
    name: 'CampainsPage',
    component: CampainsPage
  },
  {
    path: '/taahodMehrabni',
    name: 'TaahodMehrabani',
    component: TaahodMehrabani
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/reports',
    name: 'ReportsPage',
    component: ReportsPage
  },


  // Errors
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})


const requiredAuth = ['DashboardPage']
const notRequiredAuth = ['LoginPage', 'RegisterPage']
router.beforeEach((to, from, next) => {
  if (requiredAuth.includes(to.name)) {
    // check if user is logged in
    if (store.getters.isLoggedIn) {
      next()
    } else {
      next('/login')
    }
  } else if (notRequiredAuth.includes(to.name)) {
    if (store.getters.isLoggedIn) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
  window.scroll(0, 0)
})

export default router
