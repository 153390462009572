<template>
    <div class="footer">
        <div class="footer-box enamad">
            <img src="../../public/enamad.svg" alt="">
        </div>

        <div class="info">
            <div class="footer-box info-box">
                <div class="info-row">
                    <p style="text-align: right;">شهرک غرب، بلوار خوردین،‌ توحید دوم، پلاک ۲۱،‌ واحد ۸</p>
                    <p style="text-align: left;">۰۹۳۵۶۱۰۲۵۱۰</p>
                </div>
                <div class="info-row" style="margin-top: 10px;">
                    <p style="text-align: right;">شنبه تا چهارشنبه: ۱۱ الی ۱۷</p>
                    <p style="text-align: left;">hafcin@gmail.com</p>
                </div>
            </div>
            
            <div class="footer-box social-box">
                <p>شبکه های اجتماعی</p>
                <div class="social">
                    <img class="social" src="../../public/instagram.svg">
                    <img class="social" src="../../public/bale.svg">
                    <img class="social" @click="openLink('telegram')" src="../../public/telegram.svg">
                </div>
            </div>
        </div>

        <div class="footer-box others">

        </div>
    </div>
</template>

<script>
export default {
    name: 'mainFooter',
    methods: {
        openLink(type) {
            if (type === "telegram") {
                window.open("https://t.me/haf_cn");
            }
        }
    }
}
</script>

<style scoped>
    .footer {
        margin-top: 15vh;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .footer-box {
        padding: 15px;
        border-radius: 10px;
        background-color: var(--secondry-color);
        height: 250px;
        margin: 0px 1.5vw;
    }

    .enamad, .others {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15vw;
    }

    .info {
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .info-box {
        height: max-content;
        display: flex;
        flex-direction: column;
        padding: 30px;
    }

    .social-box {
        height: max-content;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 3vw - 40px); /* Added later... */
        padding: 20px;
    }

    .social {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
    }

    .info-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    
    .info-row p {
        width: 50%;
        white-space: inherit;
    }

    .social {
        margin: 0px 10px;
        cursor: pointer;
    }

    @media screen and (max-width: 740px) {
        .footer {
            flex-direction: column-reverse;
            align-items: center;
        }

        .info {
            width: 100%;
            margin: 10px 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .social-box, .info-box {
            width: 90%;
        }

        .social-box {
            margin: 10px 0px;
        }

        .enamad {
            width: 90%;
        }

        .others {
            display: none;
        }
    }
</style>