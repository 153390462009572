<template>
  <div class="reports">
    <MainHeader />

    <recentReport />

    <!-- Other Reprorts -->

    <div class="otherReports">
      <h1>گزارش های دیگر</h1>
      <otherReports />
    </div>

    <Footer />
  </div>
</template>

<script>
import MainHeader from "@/components/header.vue";
import recentReport from "./components/recent.vue";
import otherReports from "./components/otherReports.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "ReportsPage",
  components: {
    MainHeader,
    recentReport,
    otherReports,
    Footer,
  },
};
</script>

<style scoped>
.reports {
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otherReports {
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otherReports h1 {
  color: var(--secondry-purple);
  font-size: 2rem;
}

@media screen and (max-width: 1065px) {
  .reports {
    padding: 0px 0px;
  }
}
</style>
