<template>
  <div class="login-main">
    <!-- <img src="../../assets/images/logo.png" alt="logo"> -->
    <HafcnLogo />

    <div
      class="login-form"
      v-if="stage === 0 && !loading"
      @submit.prevent="SendSMS()"
    >
      <div v-if="loading">loading</div>

      <form>
        <TextInput
          label="شماره تماس"
          placeholder="09*********"
          v-model="user.phone"
          :hasError="$v.user.phone.$error || error != null"
          :focused="true"
          :error="error ? error : '* شماره تلفن شما معتبر نمی باشد.'"
        />
        <button class="submit-btn">
          دریافت کد
          <span class="material-symbols-outlined"> login </span>
        </button>
      </form>
      <p>
        حساب کاربری دارید از طریق این لینک
        <router-link to="/login" class="link">وارد شوید</router-link> کنید.
      </p>
    </div>

    <div
      class="login-form"
      v-if="stage === 1 && !loading"
      @submit.prevent="checkSMS()"
    >
      <form>
        <TextInput
          label="کد دریافتی"
          placeholder="1234"
          v-model="user.code"
          :hasError="codeWasValid"
          :focused="true"
          error="کد دریافتی وارد شده صحیح نمی باشد."
        />
        <button class="submit-btn" style="bottom: -40px">
          مرحله بعد
          <span class="material-symbols-outlined"> login </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import HafcnLogo from "@/components/logo.vue";
import TextInput from "@/components/TextInput.vue";

import { required, numeric } from "vuelidate/lib/validators";

import { requestOTP } from "@/store/requests";
import { isPhoneValid } from "@/utils/validators";

export default {
  name: "RegisterPage",
  components: {
    HafcnLogo,
    TextInput,
  },
  validations: {
    user: {
      phone: { required, numeric, isPhoneValid },
    },
  },
  data() {
    return {
      user: {
        phone: "",
      },
      stage: 0,
      codeWasValid: false,
      loading: false,
      error: null,
    };
  },
  methods: {
    SendSMS() {
      if (this.$v.user.phone.$invalid) {
        return;
      } else {
        this.loading = true;
        console.log(this.user.phone);
        requestOTP(this.user.phone).then((res) => {
          this.loading = false;
          if (res === null) {
            this.error = "خطا در دریافت اطلاعات";
            return;
          }
          this.stage++;
        });
      }
    },
    checkSMS() {
      if (this.stage != 1) return;
      this.loading = true;
      this.$store
        .dispatch("login", {
          phone: this.user.phone,
          code: this.user.code,
        })
        .then(() => {
          this.codeWasValid = true;
          this.stage++;
        })
        .catch((err) => {
          this.codeWasValid = false;
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.login-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.page-status {
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-content: center; */
  flex-direction: row;
  background: var(--secondry-color);
  border-radius: 10px;
  margin: 15px 0px;
  padding: 5px;
}

button,
.btn {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 1.4rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3.5rem;
  color: white;
  transform: rotate(180deg);
}

.active {
  background-color: white;
  border-radius: 6px;
}

.login-form {
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form form {
  width: 90%;
  padding: 40px 30px 60px 30px;
  background: var(--secondry-color);
  border-radius: 10px;
}

.login-form p {
  margin-top: 80px;
}

.login-form p .link {
  color: var(--secondry-purple);
  cursor: pointer;
  text-decoration: none;
}

.submit-btn {
  background: var(--secondry-purple);
  padding: 20px 40px;
  color: white;
  width: max-content;
  height: min-content;
  border-radius: 10px;
  position: absolute;
  font-size: 2rem;
  bottom: 60px;
}

@media screen and (max-width: 768px) {
  .login-form {
    width: 90%;
  }

  .login-form form {
    width: calc(100% - 80px);
    padding: 40px 30px 0 30px;
  }

  .submit-btn {
    position: relative;
    bottom: -20px;
  }
}
</style>
