<template>
    <div class="popup-overlay">
        <div class="inputbox">
            <TextInput label="شماره تماس جدید" placeholder="09*****"/>
    
            <button class="submit-btn">
                دریافت کد
                <span class="material-symbols-outlined">
                    login
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import TextInput from './TextInput.vue';
export default {
    name: 'popupInput',
    components: {
        TextInput
    }
}
</script>

<style scoped>
    .popup-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #4343434f;
    }

    .inputbox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40vw;
        padding: 30px 10px;
        background-color: var(--secondry-color);
        border-radius: 10px;
    }
    
    .submit-btn {        
        position: absolute;
        bottom: -4.5vw;
        left: 4vw;
        background: var(--secondry-purple);
        padding: 1.5vw 6vh;
        color: white;
        width: max-content;
        height: min-content;
        border-radius: 10px;
        position: absolute;
        font-size: 1.5rem;
    }

    button {
        background: none;
        outline: none;
        border: none;
        width: 50%;
        height: 50px;
        font-size: 0.7rem;

        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        text-decoration: none;
    }

    button span {
        font-size: 2.5rem;
        color: white;
        transform: rotate(180deg);
    }   
</style>