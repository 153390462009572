import { http } from '@/services/http'


export async function getReports() {
    try {
      const res = await http.get(`reports/?limit=10`)
      return res
    }
    catch (err) {
      if (!err.response) {
        console.log("Failed to request");
        return null
      }

      if (err.response.status === 404) {
        return {}
      } else {
        return null
      }
    }
}