import { http } from '@/services/http'
import userService from "@/services/user"

export async function requestOTP(phone) {
    const body = {       
      phone_number: String(phone), 
      }

    try {
      const res = await http.post(`accounts/sessions/send_otp/`, body);
      return res
    }
    catch {
      return null
    }
}


export async function OTPvalidation(otp, phone) {
    const body = {       
        phone_number: String(phone), 
        otp: String(otp),
        uid: crypto.randomUUID()
      }
    try {
        const response = await http.post(`accounts/sessions/otp/`, body);
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateUser(body) {
  try {
    const res = http.put(`accounts/users/${userService.getUID()}/`, body);
    return res
  }
  catch {
    return {}
  }
}