<template>
  <form class="formBox" @submit.prevent="submitMessage()">
    <div class="userInfo">
      <TextInput
        label="نام و نام خانوادگی"
        placeholder="علی احمدی"
        :options="{ small: true }"
        v-model="name"
        :hasError="$v.name.$error"
        error="لطفا نام و نام خانوادگی خود را به فارسی وارد کنید"
      />
      <TextInput
        label="شماره تماس"
        placeholder="۰۹۳۵۶۱۰۲۵۱۰"
        :options="{ small: true }"
        v-model="phone"
        :hasError="$v.phone.$error"
        error="شماره تلفن وارد شده معتبر نمی باشد"
      />
      <!-- <TextInput style="width: 200%;" label="موضوع پیام" :options="{small: true}" v-model="subject" :hasError="$v.subject.$error" error="لطفا موضوع را به زبان فارسی وارد کنید" /> -->
      <SelectInput
        label="موضوع پیام"
        :selectOptions="[
          { value: 'اشتغال', label: 'اشتغال' },
          { value: 'آموزش', label: 'اموزش' },
          { value: 'یادآور مهربانی', label: 'یادآور مهربانی' },
          { value: 'وام(احسان)', label: 'وام و طرح احسان' },
          { value: 'پزشکی', label: 'پزششکی و درمانی' },
          { value: 'مناسبتی', label: 'مناسباتی و فرهنگی' },
        ]"
        v-model="subject"
        :hasError="$v.subject.$error"
        error="لطفا موضوع را انتخاب کنید"
      />
    </div>
    <div class="textarea">
      <label>متن پیام</label>
      <textarea
        v-model="message"
        dir="rtl"
        cols="30"
        rows="10"
        :style="$v.name.$error && { border: '1px solid red' }"
      ></textarea>
      <label class="message-error" v-if="$v.name.$error"
        >لطفا این ورودی را با بیش از 10 کاراکتر پر کنید</label
      >
    </div>
    <button class="submit-btn">
      ارسال
      <span class="material-symbols-outlined"> login </span>
    </button>
  </form>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectInput from "@/components/selectInput.vue";
import { sendMessage } from "../requests";
import { isPhoneValid, isPersian } from "@/utils/validators";
import { required, numeric, minLength } from "vuelidate/lib/validators";
export default {
  name: "ContactForm",
  components: {
    TextInput,
    SelectInput,
  },
  data() {
    return {
      name: "",
      phone: "",
      subject: "",
      message: "",
    };
  },
  validations: {
    phone: { required, numeric, isPhoneValid },
    name: { required, isPersian },
    subject: { required, isPersian },
    message: { required, minLength: minLength(10) },
  },
  methods: {
    submitMessage() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      sendMessage(this.name, this.phone, this.subject, this.message);
    },
  },
};
</script>

<style scoped>
.formBox {
  width: 90%;
  margin: 0 2.5%;
  padding: 15px 15px 50px 15px;
  border-radius: 10px;
  background-color: var(--secondry-color);
}

.userInfo {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.input {
  margin: 0px 20px;
  color: var(--secondry-purple);
}

.textarea {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textarea label {
  font-size: 1.2rem;
  margin: 10px 0px;
  font-weight: 600;
}

textarea {
  resize: none;
  width: calc(100% - 20px);
  border: none;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  font-size: 20px;
  font-weight: 500;
  color: var(--secondry-purple);
}

.submit-btn {
  position: absolute;
  background: var(--secondry-purple);
  padding: 1.5vw 6vh;
  color: white;
  width: -moz-min-content;
  width: 250px;
  height: -moz-min-content;
  height: 100px;
  border-radius: 10px;
  position: absolute;
  font-size: 1.5rem;
}

button {
  background: none;
  outline: none;
  border: none;
  width: 50%;
  height: 50px;
  font-size: 2rem;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  text-decoration: none;
}

button span {
  font-size: 3rem;
  color: white;
  transform: rotate(180deg);
}

.message-error {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: darkred;
}

@media screen and (max-width: 1065px) {
  .userInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input {
    margin: 10px 0;
  }
}
</style>
